import {createSlice} from '@reduxjs/toolkit';
import {getHistory} from '../../api/History';

export const historySlice = createSlice({
  name: 'history',
  initialState: {
    history: [],
  },
  reducers: {
    setHistory: (state, action) => {
      state.history = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setHistory} = historySlice.actions;

export const getOrders = config => async dispatch => {
  try {
    const response = await getHistory(config);
    if (!response.error && response.status === 200) {
      dispatch(setHistory(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectHistory = state => state.history.history;

export default historySlice.reducer;
