import instance from './Request';

export const getCategories = async branch => {
  const request = await instance();
  let data = await request
    .get(`/category/${branch}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getTypes = async () => {
  const request = await instance();
  let data = await request
    .get('/category/list-types')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeCategoryActive = async category => {
  const request = await instance();
  let data = await request
    .post('/category/activate', {category})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newCategory = async category => {
  const request = await instance(false, true);
  let data = await request
    .post('/category', category)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editCategory = async (id, category) => {
  const request = await instance(false, true);
  let data = await request
    .put(`/category/${id}`, category)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteCategory = async id => {
  const request = await instance();
  let data = await request
    .delete(`/category/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
