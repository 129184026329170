import React, {useEffect, useState} from 'react'
import { Row, Col, Space, DatePicker, Input, Tabs, Table, Tag, Spin, Modal,Button  } from 'antd'
import moment from 'moment';
import cx from 'classnames';
import Header from "../../../components/HeaderList";
import OrderDetail from '../../../components/OrderDetail';
import { ReactComponent as CardIcon } from "../../../assets/icons/card2.svg";
import { ReactComponent as CashIcon } from "../../../assets/icons/cash2.svg";
import { ReactComponent as ManUser } from "../../../assets/icons/man-user.svg";
import {ReactComponent as Calendar} from '../../../assets/icons/calendar.svg';
import Procesando from "../../../assets/icons/Procesando.svg";
import Check from "../../../assets/icons/check-2.svg";
import styles from '../styles/OrdersUI.css'

const { RangePicker } = DatePicker;
const {Search} = Input;
const { TabPane } = Tabs;

const OrdersUI = props => {
  const {
    loading,
    history,
    order,
    openOrder,
    closeOrder,
    exportData,
    beginDate,
    endDate,
    handleDates,
    orderSearch,
  } = props;

  const statusNames = ['Recibida','Procesando','En camino', 'Modificada', 'Anulada', 'Finalizada'];

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateList, setStateList] = useState({});
  const [historyList, setHistoryList] = useState(history);
  const [typeKey, setTypeKey] = useState("1");


  const initialFetch = () => {
    const key = typeKey;
    if(key === "1") {
      setHistoryList(history);
    }
    if(key === "2") {
      setHistoryList(history.filter(x=>x.status===1));
    }
    if(key === "3") {
      setHistoryList(history.filter(x=>x.status===2));
    }
    if(key === "4") {
      setHistoryList(history.filter(x=>x.status===5));
    }
    if(key === "5") {
      setHistoryList(history.filter(x=>x.status===4));
    }
};

  const showModal = () => {
    console.log('yeap!');
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    stat();
    initialFetch();
  }, [loading, typeKey]); 

  const modalHeader = (
    <div className='header_modal'>
      <div>
        <h3 className='title_order'>Orden #235</h3>
        <p className='date_order'>Miércoles 28 de sep de 2022 9.38 AM</p>
      </div>
      <div>
        <div className='status_order'>Procesado</div>
      </div>
    </div>
  );

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date_created',
      render: val => moment(val).format('DD/MM/YY - hh:mma'),
      align: 'center',
    },
    {
      title: 'Pedido',
      dataIndex: 'cod_order',
      render: (_, text) => {
        return (
          <div>
            <div>
              <ManUser />
              <span> #{text.cod_order.substring(3)}</span>
            </div>
            <div>{text.Customer.name} {text.Customer.lastname}</div>
          </div>
        );
      }
    },
    {
      title: 'Estado',
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      render: (status, record) => {
        let color = status >= 5 ? 'geekblue' : 'green';
        if (status == 5) {
          color = 'green';
        }else if (status == 1){
          color = 'geekblue';
        }else{
          color ='yellow';
        }
        return (
          <Tag color={color} key={status}>
            {status==2 && record.type_order=='CARRYOUT'?"ORDEN LISTA":statusNames[status].toUpperCase() }
          </Tag>
        );
    },
    },
    {
      title: 'Envío a',
      dataIndex: 'id',
      render: (_, record) => {
        return (
            <span>{record.Address.address}</span>
        );
      },
    },
    {
      title: 'Método de pago',
      dataIndex: 'id',
      render: (_, record) => {
        const payment = record.order_payments[0];
        return (
          <div className={styles.paymentMethod}>
            {payment.name=='Pago Efectivo'?<CashIcon />:<CardIcon />}
            <span>{payment.name}</span>
          </div>
        );
        
      },
    },
    {
      title: 'Monto',
      dataIndex: 'total',
      render: (text) => {
        return (
          <p className={styles.amount}>$ {Number(text).toFixed(2)}</p>
        )
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (text, order) => {
        return (
          <div className='actions-order'>
            <div><a href="#" onClick={() => {openOrder(order); return false;} }><img src={Procesando} alt="Ver detalle" /></a></div>
            {order.status==5?<div><img src={Check} /></div>:<></>}
          </div>
        )
      }
    },
  ];

  const stat = () => {
    let recibido = 0;
    let procesado = 0;
    let enviando = 0;
    let finalizado = 0;
    let anulado = 0;

    history.forEach(p => {
      if(p.status == 0) {
        recibido++;
      } else if (p.status == 2) {
        enviando++;
      } else if (p.status == 1){
        procesado++;
      } else if (p.status == 4){
        anulado++;
      }
      else if (p.status == 5){
        finalizado++;
      }
    });
    
    setStateList({ preparando: procesado, encamino: enviando, finalizado: finalizado, cancelado: anulado, total: (recibido + procesado + enviando + finalizado + anulado) })
  };

  const filterData = (key, event) => {
    console.log(key);
    console.log(event);

    setTypeKey(key);
  };

  return (
    <div>
      <Header
        back="/"
        title="Historial de Ordenes"
        actions={
          <Space>
              <Search
                placeholder="Buscar por Numero Pedido"
                className={cx(styles.input, styles.search)}
                allowClear
                enterButton
                size="large"
                loading={loading}
                onSearch={orderSearch}
              />
              <RangePicker
                size="large"
                suffixIcon={<Calendar />}
                className={styles.picker}
                placeholder={['Fecha Inicio', 'Fecha Final']}
                allowClear={false}
                value={[beginDate, endDate]}
                onChange={handleDates}
              />
              <Button disabled={loading} size="large" type="primary" onClick={exportData}>
                Exportar
              </Button>
            </Space>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <Row style={{ marginTop: "20px" }}>
            <Col lg={24}>
              <div className="tabs">
                <Tabs defaultActiveKey="1"  onTabClick={filterData}>
                  <TabPane tab={`Todos( ${stateList.total} )`} key="1"></TabPane>
                  <TabPane tab={`Procesado( ${stateList.preparando} )`} key="2"></TabPane>
                  <TabPane tab={`En camino( ${stateList.encamino} )`} key="3"></TabPane>
                  <TabPane tab={`Completado( ${stateList.finalizado} )`} key="4"></TabPane>
                  <TabPane tab={`Cancelado( ${stateList.cancelado} )`} key="5"></TabPane>
                  <TabPane tab="Reembolsado( 0 )" key="6"></TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: "20px" }}>
            <Col lg={24}>
              <Table columns={columns} dataSource={historyList} />
            </Col>
          </Row>
        </>
      )}

      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={order}
        width={1150}
        closable={false}
        onCancel={closeOrder}>
        <OrderDetail order={order} close={closeOrder} />
      </Modal>
    </div>
  );
}

export default OrdersUI