import {useRef, useState, useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Form, Input, Select, Modal, TreeSelect, Button, message } from 'antd';
import {GoogleMap, useJsApiLoader, StandaloneSearchBox} from '@react-google-maps/api';
import cx from 'classnames';
import {ReactComponent as Gps} from '../../../assets/icons/gps.svg';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';
import { getZones } from '../../../api/Clients';
import { editAddress, newAddress } from '../../../redux/slices/clients';

const {TextArea} = Input;
const {TreeNode} = TreeSelect;

const libraries = ['places'];

const containerStyle = {
  width: '100%',
  height: '560px'
};

const center = {
    lat: 13.701319108430834,
    lng: -89.22441469943618,
};


const AddrEditor = ({close, address, departments, zones, handleZoneList, handleAddress}) => {
    
    const [mapLoaded, handleMapLoaded] = useState(false);
    const [deleting, handleDeleting] = useState(false);
    const [sending, handleSending] = useState(false);
    const [form] = Form.useForm();
    const searchbox = useRef(null);
    const map = useRef(null);
    const dispatch = useDispatch();
    const {isLoaded} = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyCaN5j6rS669eiak_Aml-ryQzmPAtn2O_4',
        libraries,
    });

    useEffect(() => {
        const initialFetch = async () => {
        try {
            if (address && mapLoaded) {
                const newValues = {
                    address: address.address,
                    locality: address.localityName,
                    localityId: address.localityId,
                    zone: address.zoneName,
                    zoneId: address.zone,
                    alias: address.alias,
                    geosearch: address.geosearch,
                    type: address.typeName,
                    typeId: address.type,
                    notes: address.notas
                };
                form.setFieldsValue(newValues);
                if(address.latitude && address.longitude){
                    map.current.setCenter({
                        lat: parseFloat(address.latitude),
                        lng: parseFloat(address.longitude),
                    });
                }
            }
        } catch (e) {
            console.log(e)
        }
        };
        initialFetch();
    }, [address, mapLoaded, form]);
    
    const onPlacesChanged = () => {
        const location = searchbox.current.getPlaces()[0].geometry.location;
        map.current.setCenter({
          lat: location.lat(),
          lng: location.lng(),
        });
    };

    const renderMap = () => {
        const onLoadSearchbox = ref => {
          searchbox.current = ref;
          handleMapLoaded(true);
        };
    
        const onLoadMap = ref => {
          map.current = ref;
        }
    
        return (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            onLoad={onLoadMap}
            zoom={16}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              fullscreenControl: false,
            }}
          >
            <StandaloneSearchBox
              onLoad={onLoadSearchbox}
              onPlacesChanged={
                onPlacesChanged
              }
            >
              <input
                type="text"
                placeholder="Buscar..."
                className={styles.searchbox}
              />
            </StandaloneSearchBox>
          </GoogleMap>
        );
    }

    const getDepartments = () => (
        departments.map(department => (
          <TreeNode
            key={department.id}
            selectable={false}
            value={department.id}
            title={department.label}
          >
            {department.items.map(locality => (
              <TreeNode
                key={`${department.id}-${locality.id}`}
                value={`${department.id}-${locality.id}`}
                title={locality.name}
              />
            ))}
          </TreeNode>
        ))
    );

    const populateZones = async (value) => {
        const values = value.split("-");
        const response = await getZones(values[0]);
        if(response.status === 200) {
            let zonelist = response.data.map((item) => {
                return {
                    value: item.id,
                    label: item.name
                }
            });
            handleZoneList(zonelist);
        }
    };

    const confirmDelete = () => {
        Modal.confirm({
          title: '¿Estás seguro de eliminar esta tienda?',
          content: 'No se podrá recuperar esta información.',
          okText: 'Eliminar',
          cancelText: 'Cancelar',
          okType: 'danger',
          onOk() {
            deleteAddress();
          },
          onCancel() {},
        });
    };

    const deleteAddress = async () => {
        handleDeleting(true);
        const response = await dispatch(deleteAddress(address.id));
        if (response.status === 'success') {
          message.success('Se ha borrado esta direccion');
          close();
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
        handleDeleting(false);
    }
    
    const save = async () => {
    try {
        handleSending(true);
        const values = await form.validateFields();

        const newLocality = values.locality.split('-');
        const newCenter = map.current.getCenter().toJSON();
        
        let info = {};
        if (address) {
            info.id = address.addressId;
            info.customer = address.customer;
        }
        info.tipo = isNaN(values.type)?address.type:parseInt(values.type);
        info.zona = isNaN(values.zone)?2:values.zone;
        info.alias = values.alias;
        info.principal = false;
        info.info = values.notes;
        info.latitude = "" + newCenter.lat;
        info.longitude = "" + newCenter.lng;
        info.departamento = newLocality[0];
        info.municipio = !newLocality[1]?address.localityId:parseInt(newLocality[1]);
        info.direccion = values.address;
        if (address) {
            const response = await dispatch(editAddress(address.id, info));
            if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
            } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
            }
        } else {
            const response = await dispatch(newAddress(info));
            if (response.status === 'success') {
            message.success('Cambios realizados con éxito');
            handleAddress(response.address);
            } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
            }
        }
        

        handleSending(false);
    } catch (e) {
        console.log(e)
    }
    };

    return (
        <div className={styles.editor}>
            <div className={styles.header}>
                <span className={styles.title}>
                {address ? 'Editar Dirección' : 'Nueva Dirección'}
                </span>
                <Close action={close} />
            </div>
            <Form
                name="addr-editor"
                autoComplete="off"
                form={form}
                colon={false}
                requiredMark={false}
                onFinish={save}
            >
                <Row gutter={[40, 40]}>
                    <Col span={15}>
                        <Row>
                            <Col span={24}>
                                <div className={styles.mapWrapper}>
                                {isLoaded && renderMap()}
                                {isLoaded && <Gps className={styles.gps} />}
                                </div>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={9}>
                        <Row>
                        <Col span={24}>
                                <Form.Item
                                label={ <span className={styles.label}>NOMBRE</span> }
                                name="alias"
                                className={styles.itemColumn}
                                rules={[{required: true, message: 'Ingresar Nombre de direcion'}]}
                                >
                                <Input size='large' placeholder="Mi Casa" className={styles.input} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                className={styles.itemColumn}
                                label={
                                    <span className={styles.label}>
                                    DEPARTAMENTO/MUNICIPIO
                                    </span>
                                }
                                rules={[{required: true, message: 'Ingresar municipio'}]}
                                name="locality">
                                <TreeSelect
                                    showSearch
                                    className={styles.input}
                                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                    placeholder="Seleccionar municipio"
                                    treeDefaultExpandAll
                                    size="large"
                                    onChange={populateZones}
                                    filterTreeNode={(search, item) => {
                                        return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                                    }}
                                >
                                    {getDepartments()}
                                </TreeSelect>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                label={ <span className={styles.label}>TIPO DE DIRECCION</span> }
                                name="type"
                                className={styles.itemColumn}
                                rules={[{required: true, message: 'Ingresar tiempo'}]}
                                >
                                <Select
                                    className={styles.input}
                                    options={[
                                        {
                                            value: '1',
                                            label: 'Casa',
                                        },
                                        {
                                            value: '2',
                                            label: 'Oficina',
                                        },
                                        {
                                            value: '3',
                                            label: 'Apartamento',
                                        },
                                        {
                                            value: '4',
                                            label: 'Otro',
                                        },
                                    ]}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={ <span className={styles.label}>DIRECCIÓN</span> }
                                    name="address"
                                    className={styles.itemColumn}
                                    rules={[{required: true, message: 'Ingresar dirección'}]}
                                >
                                    <TextArea rows={4} className={styles.input} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label={ <span className={styles.label}>NOTAS DIRECCIÓN</span> }
                                    name="notas"
                                    className={styles.itemColumn}
                                    rules={[{ message: 'Ingresar notas'}]}
                                >
                                    <TextArea rows={4} className={styles.input} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                <Col span={24}>
                    <div className={styles.itemSubmit}>
                    {/*address && (
                        <Button
                        loading={deleting}
                        className={cx(styles.submit, styles.delete)}
                        onClick={confirmDelete}
                        size="large"
                        type="danger">
                        ELIMINAR
                        </Button>
                    )*/}
                    <Button
                        loading={sending}
                        className={styles.submit}
                        size="large"
                        type="primary"
                        htmlType="submit">
                        GUARDAR DIRECCIÓN
                    </Button>
                    </div>
                </Col>
                </Row>
            </Form>
        </div>
    )
}
export default AddrEditor;