
import styles from '../styles/Statistics.module.css';


const Reports = props => {

    const  { selectedReport } = props;

    const Reports = ['RESUMEN', 'TOP DE PRODUCTOS', 'TIEMPOS PROMEDIOS',
                'PEDIDOS POR ZONA', 'RESUMEN DE TOTALES', 'PEDIDOS Y VENTAS',
                'ÓRDENES POR HORA', 'REPORTE DE RECOMPRAS'];

    const NewFnReport = (event) => {
        props.selectNewReport(event.target.getAttribute('data-name'));
    }

    return (
        <div>
        {
        Reports.map( (rp, idx) => {
                const rpSelect = rp === selectedReport ? styles.reportSelect : styles.reporte;
                return (
                    <div key={idx} data-name={rp} className={styles.optionReport+" "+rpSelect} onClick={ NewFnReport } >
                        {rp}
                    </div>
                ) 
            }
        )
        }
        </div>
    );
};

export default Reports;