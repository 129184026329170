import instance from "./Request";

export const getBranches = async () => {
  const request = await instance();
  let data = await request.get("/location").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getDepartments = async () => {
  const request = await instance("SV");
  let data = await request.get("/main/departments").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getSchedule = async () => {
  const request = await instance();
  let data = await request.get("/main/info-timedelivery").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const updateSchedule = async (open, close) => {
  const request = await instance();
  let data = await request
    .post("/main/info-timedelivery", {
      open,
      close,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeDelivery = async (branch) => {
  const request = await instance();
  let data = await request
    .post("/location/activate-delivery", { location: branch })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newBranch = async (branch) => {
  const request = await instance(false, true);
  let data = await request.post("/location", branch).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const editBranch = async (id, branch) => {
  const request = await instance(false, true);
  let data = await request.put(`/location/${id}`, branch).catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const deleteBranch = async (id) => {
  const request = await instance();
  let data = await request.delete(`/location/${id}`).catch((error) => {
    return {
      error,
    };
  });
  return data;
};
