import {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {logout, selectUser} from '../../redux/slices/user';
import {setLoaded} from '../../redux/slices/monitor';
import DashboardUI from './components/DashboardUI';

const Main = () => {
  const user =  useSelector(selectUser);
  const dispatch = useDispatch();
  const isAdmin = user.role.id === 2;
  const isAccountant = user.role.id === 6;
  const navigate = useNavigate();

  useEffect(() => {
    if (isAccountant) {
      navigate('/historial');
    } else if (!isAdmin) {
      navigate('/');
    }
  }, []);

  const closeSession = () => {
    dispatch(setLoaded(false));
    dispatch(logout());
  };

  return (
    <DashboardUI closeSession={closeSession} user={user} isAdmin={isAdmin} />
  );
}

export default Main;
