import {Row, Col, Form, Input, Button} from 'antd';
import {ReactComponent as Logo} from '../../../assets/logo/grupo_aldi.svg';
import {ReactComponent as Person} from '../../../assets/icons/person.svg';
import {ReactComponent as Lock} from '../../../assets/icons/lock.svg';
import styles from '../styles/LoginUI.module.css';

const LoginUI = ({login, sending, form, code}) => (
  <div className={styles.loginView}>
    <Row gutter={5}>
        <Col span={12}>

        </Col>
        <Col span={12}>
          <div className={styles.form}>
            <Logo className={styles.logo} />
            <span className={styles.subtitle}>Web Store</span>
            <span className={styles.Item}>Administrador</span>
            <Form
              className={styles.info}
              onFinish={login}
              onFinishFailed={login}
              autoComplete="off"
              form={form}
            >
              {!code ? (
                <>
                  <Form.Item
                    name="user"
                    rules={[{ required: true, message: 'Ingresa tu usuario' }]}
                  >
                    <Input
                      prefix={<Person className={styles.icon} />}
                      bordered={false}
                      size="large"
                      className={styles.input}
                      placeholder="Usuario"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[{ required: true, message: 'Ingresa tu contraseña' }]}
                  >
                    <Input.Password
                      prefix={<Lock className={styles.icon} />}
                      bordered={false}
                      size="large"
                      className={styles.input}
                      visibilityToggle={false} 
                      placeholder="Contraseña"
                    />
                  </Form.Item>
                </>
              ) : (
                <Form.Item
                  name="codeSent"
                  rules={[{required: true, message: 'Ingresa código enviado a tu correo'}]}
                >
                  <Input
                    bordered={false}
                    size="large"
                    className={styles.input}
                    placeholder="Código enviado a tu correo"
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  loading={sending}
                  className={styles.submit}>
                  {code ? 'VERIFICAR' : 'INGRESAR'}
                </Button>
              </Form.Item>
            </Form>
            <div className={styles.powered}>
              <a href="https://www.expresateweb.com/" target="_blank" rel="noreferrer">
                {/* <span>Powered by</span> */}
                <img alt="" src="https://paulaner-assets.nyc3.digitaloceanspaces.com/common-files/expresate-blanco.svg" />
              </a>
            </div>
          </div>
        </Col>
    </Row>
  </div>
);

export default LoginUI;
