import { Row, Col, Button, Modal, Spin, Switch, Tabs  } from "antd";
import cx from "classnames";
import moment from "moment";
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { ReactComponent as Trash } from "../../../assets/icons/trash.svg";
import { ReactComponent as Deactivate } from "../../../assets/icons/deactivate.svg";
import { ReactComponent as Add } from "../../../assets/icons/add-round.svg";
import { ReactComponent as Gift } from "../../../assets/icons/regalo.svg";
import Editor from "./Editor";
import styles from "../styles/BannersUI.module.css";
import { useState } from "react";

const BannersUI = (props) => {
  const {
    loading,
    editorVisible,
    editBanner,
    newBanner,
    closeEditor,
    banner,
    handleBanner,
    banners,
    handleActive,
    confirmDelete,
  } = props;

  const [activeTab, setActiveTab] = useState(1);
  const [blockCreateBanner, setBlockCreateBanner] = useState(false);
  const Banner = ({ zone, slide, index }) => (
    <div className={cx(styles.banner, {
        [styles.banneroff]: !slide.active,
      })}>
      <Row gutter={5} align="middle">
        <Col span={6}>
          <div  className={cx(styles.bannerPhoto, {
                [styles.innactive]: !slide.active,
              })}
              style={{ backgroundImage: `url(${slide.image})` }}
            />
        </Col>
        <Col span={14}>
          <div className={styles.bannerTile}>
            <h3>{slide.name}</h3>
            <span>Creado por: administrador</span>
            <span>Fecha última actualización: {moment(slide.date_updated).format('D/M/Y')}</span>
          </div>
        </Col>
        <Col span={2}>
          <div className={styles.bannerActions}>
            <div
              className={styles.actionWrap}
              onClick={() => editBanner(slide)}
            >
              <Edit /><br/>Editar
            </div>
          </div>
        </Col>
        <Col span={2}>
        <div className={styles.bannerActions}>
            <Switch checked={slide.active} onChange={() => handleActive(slide.id, zone, index, slide.active)}  />
            </div>
        </Col>
      </Row>
    </div>
  );

  const validateTab = (key, event) => {
    console.log(key);
    console.log(event);

    setActiveTab(key);
    if(key == 2) {
      setBlockCreateBanner(true);
    } else {
      setBlockCreateBanner(false);
    }
  }

  return (
    <div className={styles.view}>
      <Row>
        <Col span={24}>
          <div className={styles.header}>
            <h1>Edición de banners</h1>
            <div className={styles.actions}>
              <Button onClick={newBanner} disabled={blockCreateBanner} size="large">
                NUEVO BANNER
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      {!loading ? (
        <Row gutter={20} className={styles.toppingsWrap}>
          <Col span={24} style={{ marginLeft: "2rem" }}>
          <Tabs size="middle" tabBarGutter={20} defaultActiveKey="1" onTabClick={validateTab} style={{ fontSize: "1em",}}>
              <Tabs.TabPane tab="Slider Principal" key="1">
                  <Row gutter={40}>
                        {banners.mainslider ? (
                            <>
                                <Col span={24}>
                                {banners.mainslider.map((slide, index) => (
                                    <Banner key={slide.id} zone="mainslider" slide={slide} index={index} />
                                ))}
                                </Col>
                            </>
                        ) : null}
                    </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Slider Lateral" key="2">
              <Row gutter={40}>
                        {banners.sideslider ? (
                            <>
                                <Col span={24}>
                                {banners.sideslider.map((slide, index) => (
                                    <Banner key={slide.id} zone="sideslider" slide={slide} index={index} />
                                ))}
                                </Col>
                            </>
                        ) : null}
                    </Row>
              </Tabs.TabPane>
            </Tabs>
                
            </Col>     
        </Row>
      ) : (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      )}

      <Modal
        destroyOnClose
        visible={editorVisible}
        footer={false}
        closable={false}
        width={600}
        onCancel={closeEditor}
      >
        <Editor
          banner={banner}
          currentSection={activeTab}
          closeEditor={closeEditor}
          handleBanner={handleBanner}
        />
      </Modal>
    </div>
  );
};

export default BannersUI;
