import {createSlice} from '@reduxjs/toolkit';
import {
  getBrands,
  editBrand,
  newBrand,
  deleteBrand,
  changeBrandActive,
} from '../../api/Brands';

export const brandsSlice = createSlice({
  name: 'brands',
  initialState: {
    brands: [],
  },
  reducers: {
    setBrands: (state, action) => {
      state.brands = action.payload;
    },
    setActive: (state, action) => {
      const {brand, val} = action.payload;
      const index = state.brands.findIndex(el => el.id === brand)
      state.brands[index].active = val;
    },
    setBrand: (state, action) => {
      const index = state.brands.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.brands[index] = action.payload;
      }
    },
    addBrand: (state, action) => {
      state.brands.push(action.payload);
    },
    removeBrand: (state, action) => {
      const index = state.brands.findIndex(el => el.id === action.payload);
      if (index > -1) {
        state.brands.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {setBrands, setActive, addBrand, setBrand, removeBrand} = brandsSlice.actions;

export const getAllBrands = () => async dispatch => {
  try {
    const response = await getBrands();
    if (!response.error && response.status === 200) {
      dispatch(setBrands(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const activeBrand = (id, val) => async dispatch => {
  try {
    dispatch(setActive({brand: id, val: !val}));
    const response = await changeBrandActive(id);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
      };
    }
    dispatch(setActive({brand: id, val}));
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    dispatch(setActive({brand: id, val}));
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updateBrand = (id, data) => async dispatch => {
  try {
    const response = await editBrand(id, data);
    if (!response.error && response.status === 200) {
      if (response.data?.status === 300) {
        return {
          status: 'error',
          type: 'incomplete',
        };
      }
      dispatch(setBrand(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const createBrand = data => async dispatch => {
  try {
    const response = await newBrand(data);
    if (!response.error && response.status === 200) {
      if (response.data?.status === 300) {
        return {
          status: 'error',
          type: 'incomplete',
        };
      }
      dispatch(addBrand(response.data))
      return {
        status: 'success',
        brand: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const deleteBrandMonitor = id => async dispatch => {
  try {
    const response = await deleteBrand(id);
    if (!response.error && response.status === 200) {
      dispatch(removeBrand(id));
      return {
        status: 'success',
        // departments: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectBrands = state => state.brands.brands;

export default brandsSlice.reducer;
