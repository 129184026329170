import Header from '../../../components/HeaderList';
// import Clients from '../../../assets/sections/clients.png';
// import Drivers from '../../../assets/sections/drivers.png';
// import CatWarningIcon from '../../../assets/sections/catwarning.svg';
// import CatalogIcon from '../../../assets/sections/catalog.svg';
import ZonesIcon from '../../../assets/sections/cobertura.svg';
import Motos from '../../../assets/sections/motos.svg';
import Users from '../../../assets/sections/users.svg';
import CardSection from '../../../components/CardSection';
import styles from '../styles/SettingsUI.module.css';

const SettingsUI = () => (
  <div>
    <Header title="Ajustes" />
    <div className={styles.sections}>
      <CardSection image={Users} label="USUARIOS" route="/ajustes/usuarios" />
      <CardSection image={ZonesIcon} label="ZONAS DE COBERTURA" route="/ajustes/zonas" /> 
      <CardSection image={Motos} label="LISTA DE VEHICULOS" route="/ajustes/motos" />
      {/*
       <CardSection image={CatWarningIcon} label="AVISO CATEGORÍAS" route="/ajustes/aviso-categoria" />
       <CardSection image={CatalogIcon} label="SUBIR CATALOGO" route="/ajustes/catalogo" />
       <CardSection image={Clients} label="CLIENTES" route="/ajustes/clientes" />
       <CardSection image={Drivers} label="DRIVERS" route="/ajustes/drivers" /> 
       */}
    </div>
  </div>
);

export default SettingsUI;
