import {Row, Col } from 'antd';
import { useState } from 'react';
import styles from '../styles/Statistics.module.css';
import BoxReport from './BoxReport';
import Reports from './Reports';

const StatisticsUI = props => {

    const [Report, setReport] = useState("RESUMEN");

    const NewReport = (ReportName) =>{
        setReport(ReportName);
    } 

    return (
        <div className={styles.view}>
            <Row className={styles.rowWrap} gutter={40}>
                    <Col xs={18}>
                        <BoxReport 
                            nameReport={ Report }
                         />
                    </Col>
                    <Col xs={6}>
                        <Reports 
                            selectedReport={ Report } 
                            selectNewReport={ NewReport  } 
                         />
                    </Col>
                </Row>
        </div>
    );

};

export default StatisticsUI;