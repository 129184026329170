import instance from './Request';

export const getBrands = async () => {
  const request = await instance();
  let data = await request
    .get(`/brand/`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeBrandActive = async brand => {
  const request = await instance();
  let data = await request
    .post('/brand/activate', {brand})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newBrand = async brand => {
  const request = await instance();
  let data = await request
    .post('/brand', brand) 
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editBrand = async (id, brand) => {
  const request = await instance();
  let data = await request
    .put(`/brand/${id}`, brand)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteBrand = async id => {
  const request = await instance();
  let data = await request
    .delete(`/brand/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
