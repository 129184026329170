import { createSlice } from "@reduxjs/toolkit";
import {
  getOrders,
  acceptOrder,
  assignDriver,
  finishOrder,
  getDrivers,
  updateOrder,
  cancelOrder,
  transferOrder,
  getLoyalty,
  updateLoyalty,
  updateNotes,
  getTipAvailable,
  updateTipAvailable,
} from "../../api/Monitor";
import { changeDelivery } from "../../api/Branches";

export const monitorSlice = createSlice({
  name: "monitor",
  initialState: {
    loaded: false,
    new_orders: [],
    day_orders: [],
    locations: [],
    statistics: {
      day: {
        orders: 0,
        total: 0,
        total_wtx: 0,
      },
      week: {
        orders: 0,
        total: 0,
        total_wtx: 0,
      },
      month: {
        orders: 0,
        total: 0,
        total_wtx: 0,
      },
    },
    loyalty: {
      value: "",
      active: false,
    },
    tipAvailable: false,
  },
  reducers: {
    setNewOrders: (state, action) => {
      state.new_orders = action.payload;
    },
    setDayOrders: (state, action) => {
      state.day_orders = action.payload;
    },
    setLocations: (state, action) => {
      state.locations = action.payload;
    },
    setStatistics: (state, action) => {
      state.statistics = action.payload;
    },
    setLoaded: (state, action) => {
      state.loaded = action.payload;
    },
    setDelivery: (state, action) => {
      const { index, val } = action.payload;
      state.locations[index].delivery = val;
    },
    setLoyalty: (state, action) => {
      state.loyalty = action.payload;
    },
    setTipAvailable: (state, action) => {
      state.tipAvailable = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setNewOrders,
  setDayOrders,
  setLocations,
  setStatistics,
  setLoaded,
  setDelivery,
  setLoyalty,
  setTipAvailable,
} = monitorSlice.actions;

export const getMonitorOrders = (location, user) => async (dispatch) => {
  try {
    const response = await getOrders(location, user);
    if (!response.error && response.status === 200) {
      dispatch(setNewOrders(response.data.new_orders));
      dispatch(setDayOrders(response.data.day_orders));
      dispatch(setLocations(response.data.locations));
      dispatch(setStatistics(response.data.statistics));
      dispatch(setLoaded(true));
      return {
        status: "success",
        orders: response.data.new_orders.concat(response.data.day_orders),
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const deliveryLocation = (id, index, val) => async (dispatch) => {
  try {
    dispatch(setDelivery({ index, val: !val }));
    const response = await changeDelivery(id);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setDelivery({ index, val }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setDelivery({ index, val }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const acceptMonitorOrder = (order) => async (dispatch) => {
  try {
    const response = await acceptOrder(order);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        order: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const updateNotesClient =
  (user, address, notes, order) => async (dispatch) => {
    try {
      const response = await updateNotes(user, address, notes, order);
      if (!response.error && response.status === 200) {
        return {
          status: "success",
          order: response.data,
        };
      }
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      return {
        status: "error",
        type: "unknown",
      };
    }
  };

export const updateMonitorOrder = (order) => async (dispatch) => {
  try {
    const response = await updateOrder(order);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        order: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const assignMonitorDriver = (order, driver) => async (dispatch) => {
  try {
    const response = await assignDriver(order, driver);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        order: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const finishMonitorOrder = (order, comments) => async (dispatch) => {
  try {
    const response = await finishOrder(order, comments);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        order: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const cancelMonitorOrder = (order, reason) => async (dispatch) => {
  try {
    const response = await cancelOrder(order, reason);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        order: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const transferMonitorOrder =
  (order, branch, reason) => async (dispatch) => {
    try {
      const response = await transferOrder(order, branch, reason);
      if (!response.error && response.status === 200) {
        return {
          status: "success",
          order: response.data,
        };
      }
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      return {
        status: "error",
        type: "unknown",
      };
    }
  };

export const getBranchDrivers = (location) => async (dispatch) => {
  try {
    const response = await getDrivers(location);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        drivers: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getLoyaltyInfo = () => async (dispatch) => {
  try {
    const response = await getLoyalty();
    if (!response.error && response.status === 200) {
      const { active, promovalue } = response.data;
      dispatch(setLoyalty({ active, value: promovalue }));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getTipInfo = () => async (dispatch) => {
  try {
    const response = await getTipAvailable();
    if (!response.error && response.status === 200) {
      dispatch(setTipAvailable(response.data.propina_activa === "1"));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const handleTipInfo = (val) => async (dispatch) => {
  try {
    dispatch(setTipAvailable(val));
    const response = await updateTipAvailable(val ? "true" : "false");
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setTipAvailable(!val));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setTipAvailable(!val));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const handleLoyaltyInfo = (info) => async () => {
  try {
    const response = await updateLoyalty(info);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectNewOrders = (state) => state.monitor.new_orders;
export const selectDayOrders = (state) => state.monitor.day_orders;
export const selectLocations = (state) => state.monitor.locations;
export const selectStatistics = (state) => state.monitor.statistics;
export const selectLoaded = (state) => state.monitor.loaded;
export const selectLoyalty = (state) => state.monitor.loyalty;
export const selectTipAvailable = (state) => state.monitor.tipAvailable;

export default monitorSlice.reducer;
