import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Modal, message} from 'antd';
import {
  getAllProducts,
  selectProducts,
  removeMonitorProduct,
  removeImageProduct
} from '../../redux/slices/products';
import { getAllCategories, selectCategories } from '../../redux/slices/categories';
import { getAllBrands, selectBrands } from '../../redux/slices/brands';
import {getAllBranches} from '../../redux/slices/branches';
import exportProducts from '../../utils/exportProducts';
import ProductsUI from './components/ProductsUI';


const Products = () => {
  const [loading, handleLoading] = useState(true);
  const [product, handleProduct] = useState(null);
  const [editorVisible, handleEditorVisible] = useState(false);
  const products = useSelector(selectProducts);
  const categories = useSelector(selectCategories);
  const brands = useSelector(selectBrands);
  const dispatch = useDispatch();

  const getProducts = useCallback(async selectedBranch => {
    handleLoading(true);
    const [response] = await Promise.all([
      dispatch(getAllProducts(selectedBranch)),
      dispatch(getAllCategories(selectedBranch)),
      dispatch(getAllBrands())
    ]);
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } 
    handleLoading(false);
  }, [dispatch]);

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response_branches = await dispatch(getAllBranches());
    if (response_branches.status === 'success') {
      await getProducts(response_branches.first);
    } else {
      message.error('¡Hubo un problema!');
    }
    handleLoading(false);
  }, [dispatch, getProducts]);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);

  const openEditor = productToEdit => {
    handleProduct(productToEdit);
    handleEditorVisible(true);
  };

  const newProduct = () => {
    handleProduct(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleProduct(null);
    handleEditorVisible(false);
  };

  const confirmDelete = idProd => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar este producto?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeProduct(idProd);
      },
      onCancel() {},
    });
  };

  const removeProduct = async idProd => {
    const response = await dispatch(removeMonitorProduct(idProd));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Producto eliminado correctamente.');
    }
  };

  const deleteProdImage = async (idprod, type) => {
    const response = await dispatch(removeImageProduct(idprod, type));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Imagen eliminada correctamente.');
    }
  };


  const reloadProds = () => {
    initialFetch();
  };

  const exportData = () => {
    exportProducts(products);
  };

  return (
    <ProductsUI
      products={products}
      loading={loading}
      newProduct={newProduct}
      product={product}
      handleProduct={handleProduct}
      categories={categories}
      brands={brands}
      exportData={exportData}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      confirmDelete={confirmDelete}
      reloadProds={reloadProds}
      deleteProdImage={deleteProdImage}
    />
  );
}

export default Products;
