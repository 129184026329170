import {createSlice} from '@reduxjs/toolkit';
import {
  getCategories,
  getTypes,
  editCategory,
  newCategory,
  deleteCategory,
  changeCategoryActive,
} from '../../api/Categories';

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState: {
    categories: [],
  },
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setActive: (state, action) => {
      const {category, val} = action.payload;
      const index = state.categories.findIndex(el => el.id === category)
      state.categories[index].active = val;
    },
    setCategory: (state, action) => {
      const index = state.categories.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.categories[index] = action.payload;
      }
    },
    addCategory: (state, action) => {
      state.categories.push(action.payload);
    },
    removeCategory: (state, action) => {
      const index = state.categories.findIndex(el => el.id === action.payload);
      if (index > -1) {
        state.categories.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {setCategories, setActive, addCategory, setCategory, removeCategory} = categoriesSlice.actions;

export const getAllCategories = branch => async dispatch => {
  try {
    const response = await getCategories(branch);
    if (!response.error && response.status === 200) {
      dispatch(setCategories(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const getTypesList = () => async () => {
  try {
    const response = await getTypes();
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        types: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const activeCategory = (id, val) => async dispatch => {
  try {
    dispatch(setActive({category: id, val: !val}));
    const response = await changeCategoryActive(id);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
      };
    }
    dispatch(setActive({category: id, val}));
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    dispatch(setActive({category: id, val}));
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updateCategory = (id, data) => async dispatch => {
  try {
    const response = await editCategory(id, data);
    if (!response.error && response.status === 200) {
      if (response.data?.status === 300) {
        return {
          status: 'error',
          type: 'incomplete',
        };
      }
      dispatch(setCategory(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const createCategory = data => async dispatch => {
  try {
    const response = await newCategory(data);
    if (!response.error && response.status === 200) {
      if (response.data?.status === 300) {
        return {
          status: 'error',
          type: 'incomplete',
        };
      }
      dispatch(addCategory(response.data))
      return {
        status: 'success',
        category: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const deleteCategoryMonitor = id => async dispatch => {
  try {
    const response = await deleteCategory(id);
    if (!response.error && response.status === 200) {
      dispatch(removeCategory(id));
      return {
        status: 'success',
        // departments: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectCategories = state => state.categories.categories;

export default categoriesSlice.reducer;
