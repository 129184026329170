import Header from "../../../components/HeaderList";
import ClienteIcon from '../../../assets/sections/users.svg';
import Drivers from '../../../assets/sections/motos.svg';
import CardSection from "../../../components/CardSection";
import styles from "../styles/CustomerUI.module.css";

const CustomersUI = () => (
  <div>
    <Header title="Clientes" />
    <div className={styles.sections}>
      <CardSection image={ClienteIcon} label="CLIENTES FINALES" route="/clientes/finales" />
      <CardSection image={Drivers} label="DISTRIBUIDORES" route="/clientes/distribuidores" />
    </div>
  </div>
);

export default CustomersUI;
