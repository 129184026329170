import {saveAs} from 'file-saver';
import XLSX from 'xlsx';

const s2ab = (s) => { 
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;    
};

const exportProducts = (products) => {
  const wb = XLSX.utils.book_new();
  const title = 'Reporte de Productos';

  wb.Props = {
    Title: title,
    Subject: 'Reporte de Productos',
    Author: 'Grupo Aldi',
    CreatedDate: new Date(),
  };
  wb.SheetNames.push('Productos');
  const ws_data = [
    [
      'categoria',
      'marca',
      'sku',
      'nombre',
      'descripcion_corta',
      'descripcion_completa',
      'medida',
      'potencia',
      'potencia_real',
      'impedancia',
      'precio_venta',
      'precio_distribuidor',
      'existencia',
      'forma_venta',
      'Estado',
    ]
  ];

  products.forEach(product => {
    const newRegister = [
      product.category.name,
      product.brand.name,
      product.custom_stock.sku,
      product.name,
      product.small_desc,
      product.desc,
      product.custom_data.medida,
      product.custom_data.potencia,
      product.custom_data.potenciaReal,
      product.custom_data.impedancia,
      product.price,
      product.price2,
      product.custom_stock.cantidad,
      product.custom_stock.estiloVenta,
      product.active? 'Publicado' : 'Borrador'
    ];
    ws_data.push(newRegister);
  });
  const ws = XLSX.utils.aoa_to_sheet(ws_data);
  wb.Sheets['Productos'] = ws;
  const wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
  saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `${title}.xlsx`);
};

export default exportProducts;
