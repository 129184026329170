import { Table, Spin, Modal, Row, Col, Button, Switch } from 'antd';
import cx from 'classnames';
import Header from '../../../components/HeaderList';
import { ReactComponent as Cash } from '../../../assets/icons/cash.svg';
import { ReactComponent as Card } from '../../../assets/icons/card.svg';
import { ReactComponent as Check } from '../../../assets/icons/check.svg';
import { ReactComponent as Cancel } from '../../../assets/icons/cancel.svg';
import { ReactComponent as Edit } from "../../../assets/icons/edit.svg";
import { getColumnSearchProps } from '../../../utils/tables';
import OrderDetail from '../../../components/OrderDetail';
import styles from '../styles/ClientsUI.module.css';
import AddrEditor from './AddrEdit';
import ClientEditor from './ClientEdit';

const ClientsUI = props => {
  const {
    loading,
    client,
    order,
    openAddrEditor,
    address,
    departments,
    openOrder,
    closeOrder,
    closeEditor,
    clientEditor,
    openClientEditor,
    closeClientEditor,
    initialFetch,
    zones,
    handleZoneList,
    handleAddress,
    editorVisible,
    exportData,
    handleActive,
  } = props;

  const getIcon = status => {
    if (status === 4) {
      return <Cancel className={styles.driverIcon} />;
    }
    return <Check />;
  };

  const getMethod = status => {
    if (status === 17) {
      return <Card className={styles.methodIcon} />;
    }
    return <Cash className={styles.methodIcon} />;
  };

  const columns = [
    {
      title: 'Ticket',
      dataIndex: 'cod_order',
      align: 'center',
      ...getColumnSearchProps('cod_order'),
    },
    {
      title: 'Fecha',
      dataIndex: 'date_created',
      align: 'center',
    },
    {
      title: 'Pago',
      dataIndex: 'order_payments',
      render: val => getMethod(val[0].id),
      align: 'center',
      filters: [
        {
          value: 'Pago Efectivo',
          text: 'Efectivo',
        },
        {
          value: 'Pago Tarjeta',
          text: 'Tarjeta',
        },
      ],
      onFilter: (value, record) => record.order_payments[0].name === value,
      filterMultiple: false,
    },
    {
      title: 'Monto',
      dataIndex: 'total',
      render: (val, order) => `${order.currency}${parseFloat(val).toFixed(2)}`,
      align: 'center',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: val => getIcon(val),
      align: 'center',
      filters: [
        {
          value: 5,
          text: 'Completadas',
        },
        {
          value: 4,
          text: 'Canceladas',
        },
      ],
      onFilter: (value, record) => record.status === value,
      filterMultiple: false,
    },
  ];

  const addrColumns = [
    {
      title: 'No.',
      dataIndex: 'id',
      align: 'center'
    },
    {
      title: 'TIPO',
      dataIndex: 'typeName',
      align: 'center',
    },
    {
      title: 'MUNICIPIO',
      dataIndex: 'localityName',
      align: 'center',
    },
    {
      title: 'COLONIA',
      dataIndex: 'zoneName',
      align: 'center',
      ...getColumnSearchProps('zoneName'),
    },
    {
      title: 'DIRECCIÓN',
      dataIndex: 'address',
      align: 'center',
      ...getColumnSearchProps('address'),
    },
    {
      title: "Editar",
      align: "center",
      dataIndex: "addressId",
      render: (_, record) => (
        record.alias!='CARRYOUT' && <div className={styles.edit} onClick={() => openAddrEditor(record)}>
          <Edit />
        </div>
      ),
    }
  ];

  const addrs = client.addresses;
  let AddressList = addrs && addrs.map((dirInfo, index) => {
        return {
            id: index+1,
            customer: client.id,
            addressId: dirInfo.id,
            address: dirInfo.address,
            alias: dirInfo.alias,
            type: dirInfo.type,
            typeName: dirInfo.type_name,
            locality: dirInfo.localityId,
            localityName: dirInfo.locality.name,
            department: dirInfo.locality.department,
            departmentName: dirInfo.locality.departmentName,
            zone: dirInfo.zoneId,
            zoneName: dirInfo.zone.name,
            zoneNameAlt: dirInfo.zone.name_online,
            level: dirInfo.zone.level,
            notas: dirInfo.notas,
            geosearch: dirInfo.geosearch,
            latitude: dirInfo.latitude,
            longitude: dirInfo.longitude,
            date_updated: dirInfo.date_modified
        };
    });

  return (
    <div className={styles.view}>
      <Header
        back="/clientes/finales"
        title={loading ? 'Detalle Cliente' : `${client.name} ${client.lastname}`}
        actions={
          <div className={styles.actions}>
            <div className={styles.switchWrap}>
              <span>Activo</span>
              <Switch
                checked={client.Status}
                onChange={handleActive}
              />
            </div>
            <Button size="large" type="primary" onClick={exportData}>
              Exportar
            </Button>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <div className={styles.infoWrap}>
            <Row>
              <Col span={8}>
                <div className={styles.infoCol}>
                  <span>{`Nombre: ${client.name} ${client.lastname}`}</span>
                  <span>{`DUI: ${client.identity}`}</span>
                  <span>{`Correo: ${client.email}`}</span>
                </div>
              </Col>
              <Col span={10}>
                <div className={cx(styles.infoCol, styles.borders)}>
                  <span>{`Teléfono: ${client.phone}`}</span>
                  <span>{`Municipio: ${(client.addresses && client.addresses.length>0)?client.addresses[0].locality.name:"N/R"}`}</span>
                  <span>{`Dirección: ${(client.addresses && client.addresses.length>0)?client.addresses[0].address:"N/R"}`}</span>
                </div>
              </Col>
              <Col span={6}>
                <div className={styles.infoCol}>
                  <span>{`Teléfono 2: ${client.phone2}`}</span>
                  <span>
                    {`Primera compra: ${client.orders.length ?
                      client.orders[0].date_created :
                      'Sin compras'}`}
                  </span>
                  <span>
                    {`Última compra: ${client.orders.length ?
                      client.orders[client.orders.length - 1].date_created :
                      'Sin compras'}`}
                  </span>
                </div>
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{textAlign: "right", paddingTop: "1em"}}>
                <div className={cx(styles.infoCol, styles.WrapperbtnEdit)}>
                  <Button onClick={openClientEditor}>Editar</Button>
                </div>
              </Col>
            </Row>
          </div>
          <Row>
            <Col span={24}>
              <div className={styles.titleTable}>direcciones registradas</div>
              <Table
                  columns={addrColumns}
                  dataSource={AddressList}
                  rowKey="addressId"
                  onRow={record => (record.alias!='CARRYOUT' && {
                    onClick: () => openAddrEditor(record)
                  })}
                />
            </Col>
            <Col span={24}>
              <div className={styles.titleTable}>ordenes realizadas</div>
              <Table
                columns={columns}
                dataSource={client.orders}
                rowKey="id"
                onRow={record => ({
                  onClick: () => openOrder(record)
                })}
              />
            </Col>
          </Row>
        </div>
      )}
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={order}
        width={1150}
        closable={false}
        onCancel={closeOrder}>
        <OrderDetail order={order} close={closeOrder} userView />
      </Modal>
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={950}
      >
        <AddrEditor
          close={closeEditor}
          address={address}
          departments={departments}
          zones={zones}
          handleZoneList={handleZoneList}
          initialFetch={initialFetch}
          handleAddress={handleAddress}
        />
      </Modal>
      <Modal 
        destroyOnClose 
        closable={false} 
        visible={clientEditor} 
        footer={false} 
        onCancel={closeClientEditor} 
        width={650}>
        <ClientEditor close={closeClientEditor} client={client} />
      </Modal>
    </div>
  );
};

export default ClientsUI;
