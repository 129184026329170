
import {Row, Col, DatePicker, Button, Spin } from 'antd';
import styles from '../styles/Statistics.module.css';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getKPI_SummaryOfTotals, getLocations } from '../../../api/kpi';
import LocationSelector from '../../../components/LocationSelector';

const SummaryOfTotals = () => {

    const dateFormat = 'YYYY';
    const initialObj = {type:"year", val:moment().year(), location: []};
    const GeneralOptions = [
        {Id: 1, Text: "Total de pedidos", Value: "pedidos"},
        {Id: 2, Text: "Total de ventas", Value: "ventas"}
    ];

    //useState
    const [obj, setObj] = useState(initialObj);
    const [datos, setDatos] = useState([]);
    const [loading, handleLoading] = useState(false);
    const [defaultOption, setDefaultOption] = useState(1);
    const [locations, setLocation] = useState([]);
    const [locationValues, setLocationValues] = useState([]);
    //const [year, setYear] = useState();

    //useEffect
    useEffect(() => {
        getKPI_SummaryOfTotals(obj).then(response => {
            if(response.status === "success"){
                if(defaultOption === 2){
                    setDatos(response.ventas);

                }else if(defaultOption === 1){
                    setDatos(response.pedidos);
                }
                handleLoading(false);
            }else{
                alert("No se procesó la información.");
            }
        });

        getLocations().then(response => {
            setLocation(response.data);
        })

    }, [obj, defaultOption] )

    const GetYears = (dateString) => {
        ResetObjByYears(moment(dateString).year(), "year");
        //setYear(moment(dateString).year());
        handleLoading(true);
    };

    /*
    const GetLocalidades = (id) => {
        let Localidad_Id = id;
        let Type = "";
        //setDefaultOption(id);
    }
    */

    const ResetObjByYears = (value, type) => 
    {
        if(type === "year") {
            setObj({...obj, val: value});
        } else {
            setObj({...obj, location: value});
        }
        handleLoading(true);

        //getKPI_SummaryOfTotals(obj).then(response => {
        //    if(response.status === "success"){
        //        if(defaultOption === "ventas"){
        //            setDatos(response.ventas);
        //        }else if(defaultOption === "pedidos"){
        //            setDatos(response.pedidos);
        //        }
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //})
    }

    const ResetObj = (event) => {
        let objQuery = {type:"year", val: moment().year(), location: []};

        setDefaultOption(Number(event.target.parentElement.getAttribute('data-option')));
        setObj(objQuery);
        handleLoading(true);
        
        //getKPI_SummaryOfTotals(obj).then(response => {
        //    if(response.status === "success"){
        //        if(defaultOption === "ventas"){
        //            setDatos(response.ventas);
        //        }else if(defaultOption === "pedidos"){
        //            setDatos(response.pedidos);
        //        }
        //    }else{
        //        alert("No se procesó la información.");
        //    }
        //})
    }

    const updateLocationData = (payload) => {
        setLocationValues(payload);
        ResetObjByYears(payload, "location");
        handleLoading(true);
    }

    const chartAT = {
        series: [{
            name: 'Total',
            data: datos.map(x => x.valor)
        }],
        options:{
            chart:{
                type: 'bar'
            },
            xaxis:{
                categories: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
                labels: {
                    show: true
                }
            },
            colors:['#304278']
        }
    }

    return (
        <div>
            <div className={styles.titulo_reportes}>resumen de totales</div>
            <div className={styles.mb_10}>
                <Row className={styles.margin_top_10+" "+styles.margin_botton_10}>
                    <Col span={9}>
                        <LocationSelector dataSource={locations} selectedValues={locationValues} setSelectedValues={updateLocationData} />
                    </Col>
                    <Col span={8}>
                        <DatePicker 
                         onChange={GetYears} 
                         picker="year" 
                         className={styles.mr_2+" "+styles.margin_left_10} 
                         defaultValue={moment()} 
                         format={dateFormat}
                        />
                    </Col>
                    <Col span={7}>
                        {GeneralOptions.map(opt => {
                            const optSeleccionada = opt.Id === defaultOption ? styles.active : styles.no_active;
                            return(
                                <Button 
                                key={opt.Id} 
                                data-option={opt.Id} 
                                onClick={ResetObj} 
                                className={styles.margin_left_10+" "+optSeleccionada+" "+styles.font_weight_14}   
                                >
                                    { opt.Text }
                                </Button>
                            )
                        })}
                    </Col>
                </Row>
            </div>
            {
                loading ? (
                    <div className={styles.loader}><Spin size="large" /></div>
                ) : (
                    <div className={styles.card}>
                        <Row>
                            <div className={styles.font_weight_16+" "+styles.titulo_char_top}>
                                { defaultOption &&  GeneralOptions[defaultOption-1].Text }
                            </div>
                        </Row>
                        <Row gutter={0}>
                            <Col span={24} >
                                <Chart
                                    options={chartAT.options}
                                    series={chartAT.series}
                                    type="bar"
                                    width="100%"
                                // className={styles.area_chart_pie}
                                />
                            </Col>
                        </Row>
                    </div>
                )
            }
        </div>
    ); 
};

export default SummaryOfTotals;