import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {
  getAllBrands,
  selectBrands,
  deleteBrandMonitor,
  activeBrand,
} from '../../redux/slices/brands';
import BrandsUI from './components/BrandsUI';

const Brands = () => {
  const [loading, handleLoading] = useState(true);
  const [didLoad, setDidLoad] = useState(false);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [brand, handleBrand] = useState(null);
  const brands = useSelector(selectBrands);
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    setDidLoad(true);
    const [response] =
      await Promise.all([
        dispatch(getAllBrands())
      ]);
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
    handleLoading(false);
    setDidLoad(false);
  }, []);

  useEffect(() => {
    initialFetch();
  }, [initialFetch]);


  const openEditor = brandToEdit => {
    handleBrand(brandToEdit);
    handleEditorVisible(true);
  };

  const newBrand = () => {
    handleBrand(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleBrand(null);
    handleEditorVisible(false);
  };

  const confirmDelete = idBrand => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta marca?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeBrand(idBrand);
      },
      onCancel() {},
    });
  };

  const removeBrand = async idBrand => {
    const response = await dispatch(deleteBrandMonitor(idBrand));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Marca eliminada correctamente');
    }
  };

  const handleActive = async (idBrand, val) => {
    const response = await dispatch(activeBrand(idBrand, val));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
  };

  return (
    <BrandsUI
      loading={loading}
      brands={brands}
      brand={brand}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      newBrand={newBrand}
      confirmDelete={confirmDelete}
      handleBrand={handleBrand}
      handleActive={handleActive}
      didLoad={didLoad}
      setDidLoad={setDidLoad}
    />
  );
}

export default Brands;
