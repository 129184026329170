import Header from "../../../components/HeaderList";
import HomeIcon from "../../../assets/sections/homepage.svg";
import AboutIcon from "../../../assets/sections/about.svg";
import FaqIcon from "../../../assets/sections/faq.svg";
import ContactIcon from "../../../assets/sections/contact.svg";
import BrandIcon from '../../../assets/sections/brand.svg';
import Categories from '../../../assets/sections/categories.svg';
import CardSection from "../../../components/CardSection";
import styles from "../styles/ContentUI.module.css";

const ContentsUI = () => (
  <div>
    <Header title="Contenidos" />
    <div className={styles.sections}>
      <CardSection image={HomeIcon} label="PORTADA" route="/contenidos/portada" />
      <CardSection image={Categories} label="CATEGORÍAS" route="/contenidos/categorias" />
      <CardSection image={BrandIcon} label="MARCAS" route="/contenidos/marcas" />
       {/*
       <CardSection image={FaqIcon} label="PREGUNTAS FRECUENTES" route="/contenidos/faq" />
      <CardSection image={ContactIcon} label="CONTACTENOS" route="/contenidos/contacto" />
      
      <CardSection image={Store} label="TIENDAS" route="/contenidos/tiendas" />
      <CardSection image={Blog} label="BLOG" route="/contenidos/blogs" />
      <CardSection image={Web} label="PÁGINAS" route="/contenidos/paginas" />
      */}
    </div>
  </div>
);

export default ContentsUI;
