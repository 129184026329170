import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useParams} from 'react-router-dom';
import {message} from 'antd';
import {getClientDetails, selectClient, activeClient} from '../../redux/slices/clients';
import { getAllDepartments } from '../../redux/slices/branches';
import ClientsUI from './components/ClientsUI';
import exportOrders from '../../utils/exportOrders';

const Clients = () => {
  const [loading, handleLoading] = useState(true);
  const [order, handleOrder] = useState(null);
  const [currAddr, handleAddress] = useState(null);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [departments, handleDepartments] = useState([]);
  const [zoneList, handleZoneList] = useState([]);
  const [clientEditor, handleClientEditor] = useState(false);
  const client = useSelector(selectClient);
  const dispatch = useDispatch();
  const {id} = useParams();

  const initialFetch = useCallback(async (id) => {
    const [response, response_departments] =
    await Promise.all([
      dispatch(getClientDetails(id)),
      dispatch(getAllDepartments()),
    ]);
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      handleDepartments(response_departments.departments);
    }

    
    handleLoading(false);
  }, [dispatch]);

  useEffect(() => {
    initialFetch(id);
  }, [initialFetch, id]);

  const openOrder = newOrder => {
    handleOrder(newOrder);
  };

  const closeOrder = () => {
    handleOrder(null);
  };

  const exportData = () => {
    exportOrders(client.orders);
  };

  const newAddr = () => {
    handleAddress(null);
    handleEditorVisible(true);
  };

  const openAddrEditor = (AddrToEdit) => {
    handleAddress(AddrToEdit);
    handleEditorVisible(true);
  };

  const openClientEditor = () => {
    handleClientEditor(true);
  }

  const closeEditor = () => {
    handleAddress(null);
    handleEditorVisible(false);
  };

  const closeClientEditor = () => {
    handleClientEditor(false);
  };

  const handleActive = async () => {
    const response = await dispatch(activeClient(client.id, client.Status));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
  };

  return (
    <ClientsUI
      loading={loading}
      client={client}
      address={currAddr}
      departments={departments}
      openAddrEditor={openAddrEditor}
      handleAddress={handleAddress}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      clientEditor={clientEditor}
      handleClientEditor={handleClientEditor}
      openClientEditor={openClientEditor}
      closeClientEditor={closeClientEditor}
      newAddr={newAddr}
      order={order}
      zones={zoneList}
      handleZoneList={handleZoneList}
      openOrder={openOrder}
      closeOrder={closeOrder}
      exportData={exportData}
      handleActive={handleActive}
    />
  );
}

export default Clients;
