import {useState, useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Switch,
  message,
  Upload
} from 'antd';
import {useDispatch} from 'react-redux';
import { SortAscendingOutlined } from "@ant-design/icons";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {createBrand, updateBrand} from '../../../redux/slices/brands';
import {normFile, beforeUpload, dummyRequest} from '../../../utils/images';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';

const {Option} = Select;

const Editor = ({close, brand, handleBrand, setDidLoad}) => {
  const [sending, handleSending] = useState(false);
  const [content, handleContent] = useState('');
  const [image, handleImage] = useState("");
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      handleImage("");
      if (brand) {
        const newValues = {
          name: brand.name,
          description: brand.description,
          active: brand.active,
          position: brand.orderView
        };
        // console.log(brand);
        handleContent(brand.description);
        form.setFieldsValue(newValues);
        handleImage(brand.image || "");
      }
      else {
        const newValues = {
          name: "",
          active: true
        };
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [brand]);

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields(); 
      if (image && image.length ) {
      const info = new FormData();
      if (brand) {
        info.append('id', brand.id);
      }
      info.append('name', values.name);
      info.append('description', values.description,);
      info.append('active', values.active);
      info.append('orderView', values.position);
      info.append('image', values.image && values.image?.length ? values.image[0]?.originFileObj : image);

      if (brand) {
        const response = await dispatch(updateBrand(brand.id, info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      } else {
        const response = await dispatch(createBrand(info));
        console.log(response);
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
          handleBrand(response.brand);
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      }
    } else {
      message.error("las imagenes son requeridas");
    }
    handleSending(false);
    setDidLoad(false);
    } catch (e) {
      message.error('¡Hubo un problema! Inténtalo de nuevo');
    }
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {brand ? 'Editar Marca' : 'Nueva Marca'}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="brand-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
        className={styles.form}
      >
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  NOMBRE
                </span>
              }
              name="name"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar nombre'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  DESCRIPCIÓN
                </span>
              }
              name="description"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar descripción'}]}
            >
              <ReactQuill theme="snow" className={styles.input} value={content} onChange={handleContent}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}> 
            <Form.Item name="position" label={
                <span className={styles.label}>
                  ORDEN
                </span>} className={styles.itemColumn}>
              <InputNumber addonBefore={<SortAscendingOutlined />} defaultValue={0} />
            </Form.Item>

            <Form.Item
                name="active"
                label={
                <span className={styles.blue}>Marca visible</span>
                }
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
              name="image"
              valuePropName="imageBrand"
              getValueFromEvent={normFile}
              className={styles.itemColumn}
              label={
                <span className={styles.label}>
                  IMÁGEN
                </span>
              }
              extra={
                <span className={styles.center}>
                  (Formatos jpg/png de 1032x220px)
                </span>
            }>
              <Upload
                maxCount={1}
                customRequest={e => dummyRequest(e, handleImage)}
                beforeUpload={beforeUpload}
                listType="picture">
                <Button className={styles.uploadImage}>
                  Seleccionar
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit">
                GUARDAR MARCA
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Editor;
