import instance from './Request';

export const getVehicles = async () => {
  const request = await instance();
  let data = await request
    .get('/vehicle')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};


export const newVehicle = async vehicle => {
  const request = await instance();
  let data = await request
    .post('/vehicle', vehicle)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editVehicle = async vehicle => {
  const request = await instance();
  let data = await request
    .put(`/vehicle/${vehicle.id}`, vehicle)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteVehicle = async id => {
  const request = await instance();
  let data = await request
    .delete(`/vehicle/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
