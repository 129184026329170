import instance from './Request';

export const getHistory = async config => {
  const request = await instance();
  let data = await request
    .post('/order/history', config)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
