import {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Form, message} from 'antd';
import {signIn, validation} from '../../redux/slices/user';
import LoginUI from './components/LoginUI';

const Main = () => {
  const [sending, handleSending] = useState(false);
  const [code, handleCode] = useState(null);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const login = async () => {
    handleSending(true);
    try {
      if (!code) {
        const {user, password} = await form.validateFields();
        if (user.length && password.length) {
          const response = await dispatch(signIn(user, password));
          if (response.status === 'success') {
            handleCode(response.code);
          } else if (response.type === 'wrong-user') {
            message.error('Credenciales incorrectas');
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        } else {
          message.error('Ingresa los campos solicitados');
        }
      } else {
        const {codeSent} = await form.validateFields();
        if (codeSent.length) {
          const response = await dispatch(validation(code.token, codeSent));
          if (response.status === 'success') {
          } else if (response.type === 'wrong-user') {
            message.error('Código incorrecto');
          } else {
            message.error('¡Hubo un problema! Inténtalo de nuevo');
          }
        } else {
          message.error('Ingresa los campos solicitados');
        }
      }
    } catch (errorInfo) {
      message.error('¡Hubo un problema! Inténtalo de nuevo');
    }
    handleSending(false);
  };

  return (
    <LoginUI
      login={login}
      form={form}
      sending={sending}
      code={code}
    />
  );
}

export default Main;
