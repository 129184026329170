import {createSlice} from '@reduxjs/toolkit';
import {login, validateLogin} from '../../api/User';

const initialState = {
  info: localStorage.getItem('llao_dash')
  ? JSON.parse(localStorage.getItem('llao_dash'))
  : null,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInfo: (state, action) => {
      state.info = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setInfo} = userSlice.actions;

export const signIn = (email, password) => async dispatch => {
  try {
    const response = await login(email, password);
    if (!response.error && response.status === 200) {
      if (!response.data.errorCode) {
        return {
          status: 'success',
          code: response.data,
        };
      }
      return {
        status: 'error',
        type: 'wrong-user',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const validation = (token, code) => async dispatch => {
  try {
    const response = await validateLogin(token, code);
    if (!response.error && response.status === 200) {
      if (!response.data.errorCode) {
        await localStorage.setItem('llao_dash', JSON.stringify(response.data));
        dispatch(setInfo(response.data));
        return {
          status: 'success',
        };
      }
      return {
        status: 'error',
        type: 'wrong-user',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const logout = () => dispatch => {
  localStorage.removeItem('llao_dash');
  dispatch(setInfo(null));
};

export const selectUser = state => state.user.info;

export default userSlice.reducer;
