import instance from './Request';

export const login = async (user, password) => {
  const request = await instance();
  let data = await request
    .post('/main/login', {
      user,
      password,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const validateLogin = async (token, code) => {
  const request = await instance();
  let data = await request
    .post('/main/validate-login', {
      token,
      code,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
