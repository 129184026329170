import instance from "./Request";

export const getOrders = async (location, user) => {
  const request = await instance();
  let data = await request
    .post("/main/dashboard", { location, user })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getLoyalty = async () => {
  const request = await instance();
  let data = await request.get("/main/promo-discount").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const getTipAvailable = async () => {
  const request = await instance();
  let data = await request.get("/main/info-drivertip").catch((error) => {
    return {
      error,
    };
  });
  return data;
};

export const updateTipAvailable = async (active) => {
  const request = await instance();
  let data = await request
    .post("/main/info-drivertip", { propina: active })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const updateLoyalty = async (loyalty) => {
  const request = await instance();
  let data = await request
    .post("/main/promo-discount", loyalty)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getDrivers = async (location) => {
  const request = await instance();
  let data = await request
    .post("/main/driver-list", { location })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const updateNotes = async (user, address, notes, order) => {
  const request = await instance();
  let data = await request
    .post("/clients/add-addrnotes", {
      id: address,
      customer: user,
      info: notes,
      orderId: order,
    })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const acceptOrder = async (order) => {
  const request = await instance();
  let data = await request
    .post("/order/accept", { orderId: order })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const updateOrder = async (order) => {
  const request = await instance();
  let data = await request
    .post("/order/update", { orderId: order })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const cancelOrder = async (order, reason) => {
  const request = await instance();
  let data = await request
    .post("/order/cancel", { orderId: order, reason })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const assignDriver = async (order, driver) => {
  const request = await instance();
  let data = await request
    .post("/order/assign-driver", { orderId: order, driverId: driver })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const finishOrder = async (order, comments) => {
  const request = await instance();
  let data = await request
    .post("/order/finish", { orderId: order, comments })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const transferOrder = async (order, branch, comments = "") => {
  const request = await instance();
  let data = await request
    .post("/order/transfer", { orderId: order, location: branch, comments })
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
