import { Table, Spin, Modal, Button, Switch } from "antd";
import { Link } from "react-router-dom";
import Header from "../../../components/HeaderList";
import { ReactComponent as Eye } from "../../../assets/icons/eye.svg";
import Procesando from "../../../assets/icons/Procesando.svg";
import Check from "../../../assets/icons/check-2.svg";
import ClientDetail from "../../../components/ClientDetail";
import { getColumnSearchProps } from "../../../utils/tables";
import styles from "../styles/ClientsUI.module.css";


const ClientDistsUI = (props) => {
  const { loading, swichloading, client, clients, openClient, closeClient, handleActive, exportData, page, handlePage, setMontoMinimo, montoUsuario, markdata } = props;

  const columns = [
    {
      title: "No",
      dataIndex: "correlativo",
      align: "center",
    },
    {
      title: "Nombre",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
    },
    {
      title: "Apellido",
      dataIndex: "lastname",
      ...getColumnSearchProps("lastname"),
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      align: "center",
      ...getColumnSearchProps("phone"),
    },
    {
      title: "DUI",
      dataIndex: "identity",
      align: "center",
      ...getColumnSearchProps("identity"),
    },
    {
      title: "Correo",
      dataIndex: "email",
      align: "center",
      ...getColumnSearchProps("email"),
    },
    {
      title: "Estado",
      dataIndex: "Status",
      align: "center",
      filters: [
        { value: 1, text: "Activo" },
        { value: 0, text: "Desactivado" },
      ],
      filterMultiple: false,
      onFilter: (val, record) => !!record.Status === !!val,
      render: (val, record) => ( <><div>{val ? "Activo" : "Desactivado" }</div><Switch key={record.id} checked={record.Status} loading={swichloading} onChange={() => handleActive(record.id, !record.Status)}  /></> ),
    },
    {
        title: '',
        dataIndex: 'id',
        key: 'id',
        render: (text, cli) => {
            return (
            <div className='actions-order'>
                <div><a href="#" onClick={() => {openClient(cli); return false;} }><img src={Procesando} alt="Ver detalle" /></a></div>
            </div>
            )
        }
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        back="/clientes"
        title="Clientes (Distribuidores)"
        actions={
          <Button size="large" type="primary" onClick={exportData}>
            Exportar
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div>
            <Table
              columns={columns}
              dataSource={clients}
              rowKey="id"
              pagination={{
                defaultCurrent: page,
                onChange: handlePage,
              }}
            />
          </div>

          <Modal
          destroyOnClose
          wrapClassName={styles.modalClient}
          footer={false}
          visible={client!=null}
          width={1150}
          closable={false}
          onCancel={closeClient}>
            <ClientDetail Customer={client} close={closeClient} setMontoMinimo={setMontoMinimo} montoUsuario={montoUsuario} markdata={markdata} />
          </Modal>
        </>
      )}

      
    </div>
  );
};

export default ClientDistsUI;
