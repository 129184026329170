import { createSlice } from "@reduxjs/toolkit";
import {
  getToppings,
  changeActive,
  editTopping,
  newTopping,
  deleteTopping,
  updatePrice,
  changeOrder,
} from "../../api/Toppings";

export const toppingsSlice = createSlice({
  name: "toppings",
  initialState: {
    toppings: [],
  },
  reducers: {
    setToppings: (state, action) => {
      state.toppings = action.payload;
    },
    setToppingsList: (state, action) => {
      const { toppings, indexCategory } = action.payload;
      state.toppings[indexCategory] = toppings;
    },
    setActive: (state, action) => {
      const { indexCategory, indexTopping, val } = action.payload;
      state.toppings[indexCategory].toppings[indexTopping].active = val;
    },
    setTopping: (state, action) => {
      const { category, topping } = action.payload;
      const indexCategory = state.toppings.findIndex(
        (el) => el.id === category.id
      );
      const index = state.toppings[indexCategory].toppings.findIndex(
        (el) => el.id === topping.id
      );
      if (index > -1) {
        state.toppings[indexCategory].toppings[index] = topping;
      }
    },
    addTopping: (state, action) => {
      const { category, topping } = action.payload;
      const indexCategory = state.toppings.findIndex(
        (el) => el.id === category.id
      );
      state.toppings[indexCategory].toppings.push(topping);
    },
    removeTopping: (state, action) => {
      const { indexCategory, indexTopping } = action.payload;
      state.toppings[indexCategory].toppings.splice(indexTopping, 1);
    },
    setPrice: (state, action) => {
      state.toppings.map((category, indexCategory) => {
        category.toppings.map((topping, index) => {
          state.toppings[indexCategory].toppings[index].price = action.payload;
        });
      });
    },
    moveTopping: (state, action) => {
      const { prevIndex, nextIndex, indexCategory } = action.payload;
      const [removed] = state.toppings[indexCategory].toppings.splice(
        prevIndex,
        1
      );
      state.toppings[indexCategory].toppings.splice(nextIndex, 0, removed);
    },
    moveCategory: (state, action) => {
      const { prevIndex, nextIndex } = action.payload;
      const [removed] = state.toppings.splice(prevIndex, 1);
      state.toppings.splice(nextIndex, 0, removed);
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setToppings,
  setActive,
  addTopping,
  setTopping,
  removeTopping,
  setPrice,
  moveTopping,
  setToppingsList,
  moveCategory,
} = toppingsSlice.actions;

export const getAllToppings = (branch) => async (dispatch) => {
  try {
    const response = await getToppings(branch);
    if (!response.error && response.status === 200) {
      const { categorias, toppings } = response.data;
      const mappedCategories = categorias.map((category) => {
        category.toppings = [];
        return category;
      });
      if (toppings.length) {
        toppings.map((topping) => {
          const indexCategory = mappedCategories.findIndex(
            (el) => el.id === topping.categoryId
          );
          if (indexCategory >= 0) {
            delete topping.category;
            mappedCategories[indexCategory].toppings.push(topping);
          }
        });
        dispatch(setToppings(mappedCategories));
      } else {
        dispatch(setToppings(mappedCategories));
      }
      return {
        status: "success",
        toppings: response.data.toppings,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const activeTopping =
  (id, indexCategory, indexTopping, val) => async (dispatch) => {
    try {
      dispatch(setActive({ indexCategory, indexTopping, val: !val }));
      const response = await changeActive(id);
      if (!response.error && response.status === 200) {
        return {
          status: "success",
        };
      }
      dispatch(setActive({ indexCategory, indexTopping, val }));
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      dispatch(setActive({ indexCategory, indexTopping, val }));
      return {
        status: "error",
        type: "unknown",
      };
    }
  };

export const updateTopping = (id, data, category) => async (dispatch) => {
  try {
    const response = await editTopping(id, data);
    if (!response.error && response.status === 200) {
      dispatch(setTopping({ category, topping: response.data }));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    console.log(e);
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const createTopping = (data, category) => async (dispatch) => {
  try {
    const response = await newTopping(data);
    if (!response.error && response.status === 200) {
      dispatch(addTopping({ category, topping: response.data }));
      return {
        status: "success",
        topping: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const deleteMonitorTopping =
  (id, indexCategory, indexTopping) => async (dispatch) => {
    try {
      const response = await deleteTopping(id);
      if (!response.error && response.status === 200) {
        dispatch(removeTopping({ indexCategory, indexTopping }));
        return {
          status: "success",
          // departments: response.data,
        };
      }
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      return {
        status: "error",
        type: "unknown",
      };
    }
  };

export const handlePrice = (price) => async (dispatch) => {
  try {
    const response = await updatePrice(price);
    if (!response.error && response.status === 200) {
      dispatch(setPrice(price));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const handleOrderToppings =
  (nextIndex, id, prevIndex, indexCategory, oldList) => async (dispatch) => {
    try {
      dispatch(moveTopping({ nextIndex, prevIndex, indexCategory }));
      const response = await changeOrder(nextIndex, id, 2);
      if (!response.error && response.status === 200) {
        return {
          status: "success",
        };
      }
      dispatch(setToppingsList({ toppings: oldList, indexCategory }));
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      dispatch(setToppingsList({ toppings: oldList, indexCategory }));
      return {
        status: "error",
        type: "unknown",
      };
    }
  };

export const handleOrderCategories =
  (nextIndex, id, prevIndex, oldList) => async (dispatch) => {
    try {
      dispatch(moveCategory({ nextIndex, prevIndex }));
      const response = await changeOrder(nextIndex, id, 1);
      if (!response.error && response.status === 200) {
        return {
          status: "success",
        };
      }
      dispatch(moveCategory({ toppings: oldList }));
      return {
        status: "error",
        type: "unkown",
      };
    } catch (e) {
      dispatch(moveCategory({ toppings: oldList }));
      return {
        status: "error",
        type: "unknown",
      };
    }
  };

export const selectToppings = (state) => state.toppings.toppings;

export default toppingsSlice.reducer;
