import {useState} from 'react';
import {Button} from 'antd';
import Close from '../../../components/Close';
import styles from '../styles/ConfirmDriver.module.css';

const ConfirmDriver = ({updateOrder, close}) => {
  const [sending, handleSending] = useState(false);

  const assingMonitorOrder = async () => {
    handleSending(true);
    await updateOrder(true);
    handleSending(false);
  };

  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>Checklist</span>
        <Close action={close} />
      </div>
      <div className={styles.body}>
        <p>¿Llevas todos los productos de la orden?</p>
        <p>¿Llevas el cambio para pago en efectivo?</p>
        <p>¿Tienes dudas sobre la dirección de entrega?</p>
      </div>
      <div className={styles.action}>
        <Button
          loading={sending}
          onClick={assingMonitorOrder}
          size="large"
          type="primary"
          className={styles.submit}>
          CONFIRMAR RECEPCIÓN
        </Button>
      </div>
    </div>
  );
};

export default ConfirmDriver;
