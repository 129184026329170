import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message} from 'antd';
import {
  getOrders,
  selectHistory,
} from '../../redux/slices/history';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import {selectUser} from '../../redux/slices/user';
import HistoryUI from './components/HistoryUI';
import exportOrders from '../../utils/exportOrders';

const History = () => {
  const today = moment.tz('America/El_Salvador');
  const [loading, handleLoading] = useState(true);
  const [order, handleOrder] = useState(null);
  const history = useSelector(selectHistory);
  const user = useSelector(selectUser);
  const [endDate, handleEndDate] = useState(today);
  const [beginDate, handleBeginDate] = useState(
    moment.tz('America/El_Salvador').subtract(7, 'days')
  );
  // const isAdmin = user?.role.id === 2;
  const isAccountant = user.role.id === 6;
  const dispatch = useDispatch();

  const searchOrders = useCallback(async (newdBegin, newEnd, searchVal) => {
    handleLoading(true);
    const begin = newdBegin || beginDate
    const end = newEnd || endDate;
    const config = {
      date_begin: begin.format('DD/MM/YYYY'),
      date_end: end.format('DD/MM/YYYY'),
      searchtext: null,
      location: 0,
    };
    if (searchVal && searchVal.length) {
      config.searchtext = searchVal;
      config.date_begin = null;
      config.date_end = null;
    }
    const response = await dispatch(getOrders(config));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
    handleLoading(false);
  }, [beginDate, dispatch, endDate]);

  const handleDates = (newDates) => {
    handleBeginDate(newDates[0]);
    handleEndDate(newDates[1]);
    searchOrders(newDates[0], newDates[1]);
  };

  /* const initialFetch = useCallback(async () => {
    const response = await dispatch(getOrders(isAdmin ? 0 : user.employee_info.locationId));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
    handleLoading(false);
  }, [dispatch]); */
  const orderSearch = (val) => {
    searchOrders(false, false, val);
  };

  useEffect(() => {
    searchOrders();
  }, [searchOrders]);

  const openOrder = newOrder => {
    handleOrder(newOrder);
  };

  const closeOrder = () => {
    handleOrder(null);
  };

  const exportData = () => {
    exportOrders(history);
  };

  return (
    <HistoryUI
      loading={loading}
      history={history}
      order={order}
      openOrder={openOrder}
      closeOrder={closeOrder}
      isAccountant={isAccountant}
      exportData={exportData}
      handleDates={handleDates}
      beginDate={beginDate}
      orderSearch={orderSearch}
      endDate={endDate}
    />
  );
}

export default History;
