import {createSlice} from '@reduxjs/toolkit';
import {getUsers, editUser, newUser, deleteUser, changePassword, getRoles} from '../../api/Users';

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: [],
  },
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUser: (state, action) => {
      const index = state.users.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.users[index] = action.payload;
      }
    },
    addUser: (state, action) => {
      state.users.push(action.payload);
    },
    removeUser: (state, action) => {
      const index = state.users.findIndex(el => el.id === action.payload);
      if (index > -1) {
        state.users.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {setUsers, setUser, addUser, removeUser} = usersSlice.actions;

export const getAllUsers = () => async dispatch => {
  try {
    const response = await getUsers();
    if (!response.error && response.status === 200) {
      // Hide first user
      const newUsers = response.data;
      newUsers.splice(0, 1);
      dispatch(setUsers(newUsers));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const getAllRoles = () => async () => {
  try {
    const response = await getRoles();
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        roles: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const createUser = data => async dispatch => {
  try {
    const response = await newUser(data);
    if (!response.error && response.status === 200) {
      if (response.data?.status === 300) {
        return {
          status: 'error',
          type: 'user-found',
        };
      }
      dispatch(addUser(response.data));
      return {
        status: 'success',
        user: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    console.log(e);
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updateUser = data => async dispatch => {
  try {
    const response = await editUser(data);
    if (!response.error && response.status === 200) {
      if (response.data?.status === 300) {
        return {
          status: 'error',
          type: 'user-found',
        };
      }
      dispatch(setUser(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const removeMonitorUser = id => async dispatch => {
  try {
    const response = await deleteUser(id);
    if (!response.error && response.status === 200) {
      dispatch(removeUser(id));
      return {
        status: 'success',
        // departments: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const handlePassword = (user, password) => async dispatch => {
  try {
    const response = await changePassword(user, password);
    if (!response.error && response.status === 200) {
      return {
        status: 'success',
        // departments: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectUsers = state => state.users.users;

export default usersSlice.reducer;
