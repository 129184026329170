import instance from './Request';

export const getSlides = async () => {
  const request = await instance();
  let data = await request
    .get('/portada/slides')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changeActive = async slide => {
  const request = await instance();
  let data = await request
    .get(`/portada/slide/activate/${slide}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newBanner = async banner => {
  const request = await instance(false, true);
  let data = await request
    .post('/portada/slide', banner)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editBanner = async (id, banner) => {
  const request = await instance(false, true);
  let data = await request
    .put(`/portada/slide/${id}`, banner)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteBanner = async id => {
  const request = await instance();
  let data = await request
    .delete(`/portada/slide/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
