import styles from './styles/HeaderList.module.css';
import cx from "classnames";

const HeaderModal = ({title, className, actions}) => (
  <div className={cx(styles.header, className)}>
    <div className={styles.titleWrap}>
      <span className={styles.title}>{title}</span>
    </div>
    {actions}
  </div>
);

export default HeaderModal;