import validator from 'validator';

export function validateFloatKeyPress(value, prev) {
  if (
    validator.isFloat(value) === true ||
    value === '.' ||
    validator.isEmpty(value)
  ) {
    if (value.charAt(0) === '.') {
      return prev;
    }
    if (value.indexOf('.') > -1) {
      const number = value.split('.');
      if (number[1] > 99) {
        return prev;
      }
      return value;
    }
    return value;
  }
  return prev;
}
