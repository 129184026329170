import {useState, useEffect, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {
  getAllCategories,
  selectCategories,
  getTypesList,
  deleteCategoryMonitor,
  activeCategory,
} from '../../redux/slices/categories';
import {getAllBranches, selectBranches} from '../../redux/slices/branches';
import CategoriesUI from './components/CategoriesUI';

const Categories = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [category, handleCategory] = useState(null);
  const [branch, handleBranch] = useState(null);
  const [types, handleTypes] = useState([]);
  const categories = useSelector(selectCategories);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();

  const getCategories = useCallback(async selectedBranch => {
    handleLoading(true);
    const [response, response_types] = await Promise.all([
      dispatch(getAllCategories(selectedBranch)),
      dispatch(getTypesList()),
    ]);
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      handleTypes(response_types.types);
    }
    handleLoading(false);
  }, [dispatch]);

  const initialFetch = useCallback(async () => {
    handleLoading(true);
    const response_branches = await dispatch(getAllBranches());
    if (response_branches.status === 'success') {
      handleBranch(response_branches.first);
      await getCategories(response_branches.first);
    } else {
      message.error('¡Hubo un problema!');
    }
    handleLoading(false);
  }, [dispatch, getCategories]);


  useEffect(() => {
    initialFetch();
  }, [initialFetch]);


  const openEditor = categoryToEdit => {
    handleCategory(categoryToEdit);
    handleEditorVisible(true);
  };

  const newCategory = () => {
    handleCategory(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleCategory(null);
    handleEditorVisible(false);
  };

  const confirmDelete = idCategory => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta categoría?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeCategory(idCategory);
      },
      onCancel() {},
    });
  };

  const removeCategory = async idCategory => {
    const response = await dispatch(deleteCategoryMonitor(idCategory));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Categoría eliminada correctamente');
    }
  };

  const handleActive = async (idCategory, val) => {
    const response = await dispatch(activeCategory(idCategory, val));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
  };

  const selectBranch = e => {
    handleBranch(e);
    getCategories(e);
  };

  return (
    <CategoriesUI
      loading={loading}
      categories={categories}
      branches={branches}
      category={category}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      newCategory={newCategory}
      confirmDelete={confirmDelete}
      handleCategory={handleCategory}
      handleActive={handleActive}
      branch={branch}
      selectBranch={selectBranch}
      types={types}
    />
  );
}

export default Categories;
