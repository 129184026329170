import { useState, useEffect } from "react";
import { Row, Col, Typography, Badge  } from "antd";
import { ClockCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import cx from "classnames";
import { ReactComponent as Cart } from "../assets/icons/cart.svg";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import { ReactComponent as Cancel } from "../assets/icons/cancel.svg";
import Close from "../components/Close";
import styles from "./styles/ClientDetail.module.css";

const { Paragraph } = Typography;

const ClientDetail = (props) => {

    const {
        Customer,
        monitor,
        close,
        setMontoMinimo,
        montoUsuario,
        markdata
    } = props;

    return (
        <div key={Customer.id} className={styles.customerInfo}>
            <Row>
                <Col xs={24}>
                    <div className={styles.header}>
                    <span className={styles.title}>Informacion del Distribuidor</span>
                    <Close action={close} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className={styles.clientInfo}>
                        <h2 className={styles.labelComercial}>{Customer.comercialName}</h2>
                        <div className={styles.clientDetail}>
                            <span className={styles.labelClient}>NOMBRE O RAZÓN SOCIAL</span>
                            <span className={styles.labelResp}>{`${Customer.name} ${Customer.lastname}`}</span>
                        </div>
                        <div className={styles.clientDetail}>
                            <Row>
                                <Col span={12}>
                                    <span className={styles.labelClient}>TIPO DE PERSONA</span>
                                    <span className={styles.labelResp}>{`${Customer.personType=="N"?"NATURAL":"JURIDICA"}`}</span>
                                </Col>
                                <Col span={12}>
                                    <span className={styles.labelClient}>CONTRIBUYENTE DE IVA</span>
                                    <span className={styles.labelResp}>{`${Customer.personLegal?"NO":"SI"}`}</span>
                                </Col>
                            </Row>
                        </div>

                        <div className={styles.clientDetail}>
                            <Row>
                                <Col span={12}>
                                    <span className={styles.labelClient}>DUI</span>
                                    <span className={styles.labelResp}>{`${Customer.identity}`}</span>
                                </Col>
                                <Col span={12}>
                                    <span className={styles.labelClient}>NRC</span>
                                    <span className={styles.labelResp}>{`${Customer.document2}`}</span>
                                </Col>
                            </Row>
                        </div>
                        
                        <div className={styles.clientDetail}>
                            <span className={styles.labelClient}>CORREO ELECTRÓNICO</span>
                            <span className={cx(styles.labelResp, styles.clientAddress)}>{`${Customer.email}`}</span>
                            
                        </div>
                        <div className={styles.clientDetail}>
                            <Row>
                                <Col span={12}>
                                    <span className={styles.labelClient}>TELÉFONO FIJO</span>
                                    <span className={cx( styles.labelResp, styles.clientPhone )}>{Customer.phone}</span>
                                </Col>
                                <Col span={12}>
                                    <span className={styles.labelClient}>TELÉFONO CELULAR</span>
                                    <span className={cx( styles.labelResp, styles.clientPhone )}>{Customer.phone2}</span>
                                </Col>
                            </Row>
                        </div>

                        <div className={styles.clientDetail}>
                            <span className={styles.labelClient}>DIRECCIÓN</span>
                            <span className={cx(styles.labelResp, styles.clientAddress)}>
                                {
                                    Customer.addresses &&  Customer.addresses.map( addr => (
                                        <>
                                        { addr.alias != 'CARRYOUT' && 
                                            <div className={addr.id}>
                                                <strong># {addr.alias} </strong><br/>
                                                direccion: {`${addr.address}, Casa ${addr.numberDir} `}<br/>
                                                Referencia: {addr.reference}
                                            </div>
                                        }
                                        </>
                                    ))
                                }
                            </span>
                            
                        </div>
                    </div>
                </Col>
                <Col xs={12}>
                    {/* MONTO MINIMO POR DISTRIBUIDOR */}
                    <div className={styles.priceWrap}>
                        <span className={styles.priceTitle}>Compra mínima</span> 
                        <Paragraph style={{marginBottom: "0"}} mark={markdata} delete={markdata} italic={markdata} editable={{ onChange: (value) => setMontoMinimo(value, Customer.id), maxLength: 5 }}>{montoUsuario}</Paragraph>
                        <Badge count={markdata ? <ClockCircleOutlined style={{ color: '#f5222d' }} /> : 0} />
                    </div>
                    {/* HISTORIAL DE ORDENES */}
                    <div className={styles.historyWrap}>
                        <span className={styles.labelSection}>
                        {Customer.orders && Customer.orders.length > 0
                            ? "COMPRAS RECIENTES"
                            : "SIN COMPRAS"}
                        </span>
                        {Customer.orders && Customer.orders.length > 0 && (
                        <div className={styles.history}>
                            {Customer.orders.map((ordr) => (
                                <div className={styles.prevOrder}>
                                    <span className={cx(styles.cellstyle, styles.cellIcon)}><Cart /></span>
                                    <span className={styles.cellstyle}>{ordr.cod_order}</span>
                                    <span className={styles.cellstyle}>{moment(ordr.date_created, 'DD/MM/YYYY hh:mm A').format("DD/MM/YYYY")}</span>
                                    <span className={styles.cellstyle}>{ordr.status === 5 ? <Check /> : <Cancel />}</span>
                                    <span className={cx(styles.cellstyle, styles.totalPrevOrder)}>{`${ordr.currency} ${parseFloat(ordr.total).toFixed(2)}`}</span>
                                </div>
                            ))}
                        </div>
                        )}
                        {/* 
                        <Link
                            to={`/clientes/${Customer.id}`}
                            className={styles.seeMore}
                        >
                            Ver historial completo
                        </Link>
                        */}
                    </div>
                </Col>
            </Row>
        </div>
      );
};

export default ClientDetail;