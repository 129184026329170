import AverageTimes from './AverageTimes';
import HourlyOrders from './HourlyOrders';
import OrdersAndSales from './OrdersAndSales';
import OrdersByZones from './OrdersByZones';
import RepurchaseReport from './RepurchaseReport';
import Resumen from './Resumen';
import SummaryOfTotals from './SummaryOfTotals';
import TopProducts from './TopProducts';


const BoxReport = props => {

    const { nameReport } = props;

    let selectReport = <Resumen />;
    
    //Seleccionamos el reporte
    switch(nameReport) {
        case 'TOP DE PRODUCTOS':
            selectReport = <TopProducts/>;
            break;
        case 'TIEMPOS PROMEDIOS':
            selectReport = <AverageTimes />;
            break;
        case 'PEDIDOS POR ZONA':
            selectReport = <OrdersByZones />;
            break;
        case 'PEDIDOS Y VENTAS':
            selectReport = <SummaryOfTotals />;
            break;
        case 'RESUMEN DE TOTALES':
            selectReport = <OrdersAndSales/>;
            break;
        case 'ÓRDENES POR HORA':
            selectReport = <HourlyOrders/>;
            break;
        case 'REPORTE DE RECOMPRAS':
            selectReport = <RepurchaseReport/>; 
            break;
        default:
            selectReport = <Resumen />;
    };

    return (
        <>{ selectReport }</>
    )

};

export default BoxReport;