import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import moment from "moment-timezone";
import {
  selectDayOrders,
  selectNewOrders,
  selectLocations,
  selectStatistics,
  selectLoaded,
  getMonitorOrders,
  acceptMonitorOrder,
  updateMonitorOrder,
  assignMonitorDriver,
  finishMonitorOrder,
  cancelMonitorOrder,
  deliveryLocation,
  getBranchDrivers,
  transferMonitorOrder,
  getLoyaltyInfo,
  selectLoyalty,
  setLoyalty,
  handleLoyaltyInfo,
  updateNotesClient,
  getTipInfo,
  selectTipAvailable,
  handleTipInfo,
} from "../../redux/slices/monitor";
import {
  getDeliverySchedule,
  updateDeliverySchedule,
} from "../../redux/slices/branches";
import { selectUser } from "../../redux/slices/user";
import { validateFloatKeyPress } from "../../utils/format";
import MonitorUI from "./components/MonitorUI";

const Monitor = () => {
  const [modalOrder, handleModalOrder] = useState(false);
  const [modalCommentaries, handleModalCommentaries] = useState(false);
  const [modalDriverConfirm, handleModalDriverConfirm] = useState(false);
  const [scheduleVisible, setScheduleVisible] = useState(false);
  const [orderDetail, handleOrderDetail] = useState({});
  const [sending, handleSending] = useState(false);
  const [sendingTip, handleSendingTip] = useState(false);
  const [times, setTimes] = useState([moment(), moment()]);
  const newOrders = useSelector(selectNewOrders);
  const dayOrders = useSelector(selectDayOrders);
  const branches = useSelector(selectLocations);
  const statistics = useSelector(selectStatistics);
  const loaded = useSelector(selectLoaded);
  const user = useSelector(selectUser);
  const loyalty = useSelector(selectLoyalty);
  const tipAvailable = useSelector(selectTipAvailable);
  const isDriver = user?.role.id === 4;
  const isAdmin = user?.role.id === 2;
  const dispatch = useDispatch();

  const initialFetch = useCallback(async () => {
    const [response, response_schedule] = await Promise.all([
      dispatch(
        getMonitorOrders(
          isAdmin ? 0 : user.employee_info.locationId,
          user.userid
        )
      ),
      dispatch(getDeliverySchedule()),
    ]);
    if (response.status !== "success") {
      message.error("Hubo un problema");
    }
  }, [dispatch, isAdmin, user.userid]);

  useEffect(() => {
    const getEverything = async () => {
      await dispatch(getLoyaltyInfo());
      await dispatch(getTipInfo());
      initialFetch();
    };
    getEverything();
  }, [dispatch, initialFetch]);

  useEffect(() => {
    const countup = setInterval(initialFetch, 30000);

    return () => {
      clearInterval(countup);
    };
    // initialFetch();
  }, [initialFetch]);

  const openDetails = (order) => {
    handleOrderDetail(order);
    handleModalOrder(true);
  };

  const closeDetails = () => {
    handleModalOrder(false);
    handleOrderDetail({});
  };

  const openDriverConfirm = (order) => {
    handleModalDriverConfirm(true);
  };

  const closeDriverConfirm = () => {
    handleModalDriverConfirm(false);
    handleModalOrder(true);
  };

  const openModalCommetaries = (type) => {
    handleModalCommentaries(type);
  };

  const closeModalCommetaries = () => {
    handleModalCommentaries(false);
    handleModalOrder(true);
  };

  const acceptOrder = async () => {
    const response = await dispatch(acceptMonitorOrder(orderDetail.id));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Orden en preparación");
    }
  };

  const updateNotes = async (notes) => {
    const response = await dispatch(
      updateNotesClient(
        orderDetail.Customer.userId,
        orderDetail.Address.id,
        notes,
        orderDetail.id
      )
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Notas editadas");
    }
  };

  const updateOrder = async () => {
    const response = await dispatch(updateMonitorOrder(orderDetail.id));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      const text = response.order.type_order=='CARRYOUT'?"Orden lista":"Orden en camino";
      handleOrderDetail(response.order);
      await initialFetch();
      message.success(text);
      if (isDriver) {
        closeDriverConfirm();
      }
    }
  };

  const assignOrder = async (driver) => {
    const response = await dispatch(
      assignMonitorDriver(orderDetail.id, driver)
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Orden asignada");
    }
  };

  const transferOrder = async (branch) => {
    const response = await dispatch(
      transferMonitorOrder(orderDetail.id, branch)
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Orden trasnferida");
    }
  };

  const finishOrder = async (comments = "") => {
    const response = await dispatch(
      finishMonitorOrder(orderDetail.id, comments)
    );
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Orden finalizada");
      closeModalCommetaries();
    }
  };

  const cancelOrder = async (reason = "") => {
    const response = await dispatch(cancelMonitorOrder(orderDetail.id, reason));
    if (response.status !== "success") {
      message.error("Hubo un problema");
    } else {
      handleOrderDetail(response.order);
      await initialFetch();
      message.success("Orden cancelada");
      closeModalCommetaries();
    }
  };

  const getDriversBranch = async (location) => {
    const response = await dispatch(getBranchDrivers(location));
    if (response.status !== "success") {
      message.error("Hubo un problema");
      return [];
    }
    return response.drivers;
  };

  const handleDelivery = async (idBranch, index, val) => {
    const response = await dispatch(deliveryLocation(idBranch, index, val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    }
  };

  const changeLoyaltyInfo = async () => {
    handleSending(true);
    const response = await dispatch(
      handleLoyaltyInfo({
        active: loyalty.active,
        promovalue: loyalty.value || 0,
      })
    );
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Datos actualizados");
    }
    handleSending(false);
  };

  const handleTipAvailable = async (val) => {
    handleSendingTip(true);
    const response = await dispatch(handleTipInfo(val));
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Cambios guardados");
    }
    handleSendingTip(false);
  };

  const newValueLoyalty = async (e) => {
    const val = await validateFloatKeyPress(e.target.value);
    if (val || val === "") {
      dispatch(setLoyalty({ value: val, active: loyalty.active }));
    }
  };

  const newActiveLoyalty = () => {
    dispatch(setLoyalty({ value: loyalty.value, active: !loyalty.active }));
  };

  const updateSchedule = async () => {
    const response = await dispatch(
      updateDeliverySchedule(times[0].format("HHmm"), times[1].format("HHmm"))
    );
    if (response.status !== "success") {
      message.error("¡Hubo un problema!");
    } else {
      message.success("Horario editado correctamente");
    }
  };

  return (
    <MonitorUI
      modalOrder={modalOrder}
      openDetails={openDetails}
      newOrders={newOrders}
      dayOrders={dayOrders}
      branches={branches}
      statistics={statistics}
      loaded={loaded}
      user={user}
      isAdmin={isAdmin}
      isDriver={isDriver}
      orderDetail={orderDetail}
      acceptOrder={acceptOrder}
      updateOrder={updateOrder}
      assignOrder={assignOrder}
      finishOrder={finishOrder}
      cancelOrder={cancelOrder}
      closeDetails={closeDetails}
      getDriversBranch={getDriversBranch}
      handleDelivery={handleDelivery}
      modalDriverConfirm={modalDriverConfirm}
      closeDriverConfirm={closeDriverConfirm}
      openDriverConfirm={openDriverConfirm}
      openModalCommetaries={openModalCommetaries}
      closeModalCommetaries={closeModalCommetaries}
      modalCommentaries={modalCommentaries}
      transferOrder={transferOrder}
      loyalty={loyalty}
      newValueLoyalty={newValueLoyalty}
      newActiveLoyalty={newActiveLoyalty}
      changeLoyaltyInfo={changeLoyaltyInfo}
      sending={sending}
      updateNotes={updateNotes}
      tipAvailable={tipAvailable}
      handleTipAvailable={handleTipAvailable}
      sendingTip={sendingTip}
      scheduleVisible={scheduleVisible}
      setScheduleVisible={setScheduleVisible}
      times={times}
      setTimes={setTimes}
      updateSchedule={updateSchedule}
    />
  );
};

export default Monitor;
