import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message} from 'antd';
import {getAllDistClients, selectDistClients, setPage, selectPage, activeClient, getMontoMinimo, setClientMinAmount, selectDistLoaded, setDistLoaded} from '../../redux/slices/clients';
import ClientDistsUI from './components/ClientDistUI';
import exportClients from '../../utils/exportClients';

const ClientDists = () => {
  const loaded = useSelector(selectDistLoaded);
  const [loading, handleLoading] = useState(!loaded); 
  const [swichloading, handleswichLoading] = useState(false);
  const [markdata, handleMarkData] = useState(false);
  const [client, handleClient] = useState(null);
  const [montoUsuario, setMontoUsuario] = useState(0);
  const clients = useSelector(selectDistClients);
  const page = useSelector(selectPage);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (!loaded) {
        const [response] = await Promise.all([
          dispatch(getAllDistClients()),
        ]);
        if (response.status !== 'success') {
          message.error('¡Hubo un problema!');
        }
        handleLoading(false);
        handleswichLoading(false);
        dispatch(setDistLoaded(true));
      }
    };
    initialFetch();
  }, [dispatch, loaded]);

  const exportData = () => {
    exportClients(clients);
  };

  const handlePage = newPage => {
    dispatch(setPage(newPage));
  };

  const openClient = async newClient => {
    if(newClient.comercialMinShop === 0) {
      setMontoUsuario(await getMontoCompra(newClient.id));
    } else {
      setMontoUsuario(newClient.comercialMinShop);
    }
    handleClient(newClient);
  };

  const closeClient = () => {
    handleClient(null);
  };

  const handleActive = async (id, Status) => {
    handleswichLoading(true);
    const response = await dispatch(activeClient(id, Status));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
    dispatch(setDistLoaded(false));
  };

  const setMontoMinimo = async (value, client) => {
   // console.log(value);
   // console.log(client);
    handleMarkData(true);
    const response = await dispatch(setClientMinAmount(value, client));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
    setMontoUsuario(await getMontoCompra(client));
    handleMarkData(false);
   //setMontoUsuario(value);
  };

  const getMontoCompra = async (user) => {
    const response = await dispatch(getMontoMinimo(user));
    if (response.status !== "success") {
      message.error("Hubo un problema");
      return 0;
    }
    return response.info.amount_min;
  };

  return (
    <ClientDistsUI
      loading={loading}
      swichloading={swichloading}
      client={client}
      clients={clients}
      openClient={openClient}
      closeClient={closeClient}
      handleActive={handleActive}
      exportData={exportData}
      page={page}
      handlePage={handlePage}
      setMontoMinimo={setMontoMinimo}
      markdata={markdata}
      montoUsuario={montoUsuario}
    />
  );
}

export default ClientDists;
