import {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {message, Modal} from 'antd';
import {
  getAllZones,
  selectZones,
  getAllLevels,
  removeMonitorZone,
  activeZone,
} from '../../redux/slices/zones';
import {getAllDepartments, getAllBranches, selectBranches} from '../../redux/slices/branches';
import ZonesUI from './components/ZonesUI';

const Zones = () => {
  const [loading, handleLoading] = useState(true);
  const [editorVisible, handleEditorVisible] = useState(false);
  const [zone, handleZone] = useState(null);
  const [departments, handleDepartments] = useState([]);
  const [levels, handleLevels] = useState([]);
  const zones = useSelector(selectZones);
  const branches = useSelector(selectBranches);
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      const [response, res_departments, res_levels] = await Promise.all([
        dispatch(getAllZones()),
        dispatch(getAllDepartments()),
        dispatch(getAllLevels()),
        dispatch(getAllBranches())
      ]);
      if (response.status !== 'success') {
        message.error('¡Hubo un problema!');
      } else {
        handleDepartments(res_departments.departments);
        handleLevels(res_levels.levels);
      }
      handleLoading(false);
    };
    initialFetch();
  }, [dispatch]);


  const openEditor = zoneToEdit => {
    handleZone(zoneToEdit);
    handleEditorVisible(true);
  };

  const newZone = () => {
    handleZone(null);
    handleEditorVisible(true);
  };

  const closeEditor = () => {
    handleZone(null);
    handleEditorVisible(false);
  };

  const confirmDelete = (idZone) => {
    Modal.confirm({
      title: '¿Estás segúro de eliminar esta cobertura?',
      content: 'Esta información no se podrá recuperar.',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      async onOk() {
        await removeZone(idZone);
      },
      onCancel() {},
    });
  };

  const removeZone = async (idZone) => {
    const response = await dispatch(removeMonitorZone(idZone));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    } else {
      message.success('Zona eliminada correctamente');
    }
  };

  const handleActive = async (idZone, val) => {
    const response = await dispatch(activeZone(idZone, val));
    if (response.status !== 'success') {
      message.error('¡Hubo un problema!');
    }
  };

  return (
    <ZonesUI
      loading={loading}
      zones={zones}
      branches={branches}
      zone={zone}
      openEditor={openEditor}
      editorVisible={editorVisible}
      closeEditor={closeEditor}
      departments={departments}
      levels={levels}
      newZone={newZone}
      confirmDelete={confirmDelete}
      handleZone={handleZone}
      handleActive={handleActive}
    />
  );
}

export default Zones;
