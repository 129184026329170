import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Row, Col, Select, Button, message, Input } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";
import { selectUser } from "../redux/slices/user";
import { ReactComponent as Cash } from "../assets/icons/cash.svg";
import { ReactComponent as Card } from "../assets/icons/card.svg";
import { ReactComponent as Driver } from "../assets/icons/driver.svg";
import { ReactComponent as Cart } from "../assets/icons/cart.svg";
import { ReactComponent as Check } from "../assets/icons/check.svg";
import { ReactComponent as Cancel } from "../assets/icons/cancel.svg";
import Close from "../components/Close";
import styles from "./styles/OrderDetail.module.css";

const { Option } = Select;
const { TextArea } = Input;

const OrderDetail = (props) => {
  const [drivers, handleDrivers] = useState([]);
  const [selectDriver, handleSelectedDriver] = useState(null);
  const [selectBranch, handleSelectedBranch] = useState(null);
  const [loading, handleLoading] = useState(true);
  const [sending, handleSending] = useState(false);
  const [assing, handleAssing] = useState(false);
  const [updatingNotes, setUpdatingNotes] = useState(false);
  const [notes, setNotes] = useState("");
  const user = useSelector(selectUser);
  const isDriver = user?.role.id === 4;
  const isAdmin = user?.role.id === 2;

  const {
    order,
    monitor,
    close,
    acceptOrder,
    updateOrder,
    assignOrder,
    openModalCommetaries,
    getDriversBranch,
    openDriverConfirm,
    userView,
    branches,
    transferOrder,
    updateNotes,
  } = props;

  const {
    status,
    Address,
    Customer,
    cod_order,
    type_order,
    order_details,
    currency,    
    driver_tip,
    invoice,
    total,
    order_payments,
    change,
    location,
    employeeId,
    date_status_1,
    date_status_2,
    date_status_4,
    date_status_5,
    annotations,
    employee,
    extra_info,
    delivery_fee,
    delivery_track
  } = order;

  // Generamos una variable para gestionar por floti.io
  const driverfloti = 1;

  useEffect(() => {
    const initialFect = async () => {
      if (monitor) {
        const newDrivers = await getDriversBranch(isAdmin ? 0 : location.id);
        handleDrivers(newDrivers);
        handleLoading(false);
        if (employeeId) {
          handleSelectedDriver(employeeId);
        }
      }
      setNotes(Address.notas || "");
    };
    initialFect();
  }, []);

  const getStatus = () => {
    if (status === 1) {
      return "en preparación";
    } else if (status === 2) {
      return "en camino";
    } else if (status === 0) {
      return "pendiente";
    } else if (status === 5) {
      return "finalizada";
    }
    return "cancelada";
  };

  const accepMonitorOrder = async () => {
    handleSending(true);
    await acceptOrder();
    handleSending(false);
  };

  const updateMonitorOrder = async () => {
    if(type_order == 'CARRYOUT') {
      handleSending(true);
      await updateOrder();
      handleSending(false);
    } 
    else {
      if (selectDriver) {
        if (!isDriver) {
          handleSending(true);
          await updateOrder();
          handleSending(false);
        } else {
          openDriverConfirm();
        }
      } else {
        message.error("Esta orden no tiene un driver asignado");
      }
    }
  };

  const updateNotesClient = async () => {
    setUpdatingNotes(true);
    await updateNotes(notes);
    setUpdatingNotes(false);
  };

  const assingMonitorOrder = async () => {
    handleAssing(true);
    await assignOrder(selectDriver);
    handleAssing(false);
  };

  const transferMonitorOrder = async () => {
    handleAssing(true);
    await transferOrder(selectBranch);
    handleAssing(false);
  };

  const finishMonitorOrder = async () => {
    openModalCommetaries("finish");
  };

  const cancelMonitorOrder = async () => {
    openModalCommetaries("cancel");
  };

  const getIconPayment = (payment) => {
    if (payment === "Pago Efectivo") {
      return <Cash className={styles.methodIcon} />;
    } else if (payment === "Pago Tarjeta") {
      return <Card className={styles.methodIcon} />;
    }
    return <span className={cx(styles.methodIcon, styles.discount)}>%</span>;
  };

  const getInfoPayment = (payment) => {
    if (payment.name === "Pago Efectivo") {
      return (
        <span className={styles.cashback}>
          Cambio:{" "}
          {`${currency} ${parseFloat(change==0?0:(change - payment.amount)).toFixed(2)}`}
        </span>
      );
    } else if (payment.name === "Descuento Orden") {
      return (
        <span className={styles.cashback}>
          Valor: {`${currency} ${parseFloat(payment.amount).toFixed(2)}`}
        </span>
      );
    }
  };

  const getNamePayment = (payment) => {
    if (payment.name === "Descuento Orden") {
      const comment = JSON.parse(payment.comment);
      return `Descuento ${comment.value}%`;
    } else if (payment.name === "Pago Efectivo") {
      return `Pago Efectivo - ${currency} ${parseFloat(change==0?payment.amount:change).toFixed(2)}`;
    }
    return payment.name;
  };

  const getInvoice = () => {
    const info = JSON.parse(invoice);
    return (
      <Row>
        <Col xs={24} lg={24}>
          <div className={styles.stepsWrap}>
            <span className={styles.labelSection}>FACTURA</span>
            <div className={styles.steps}>
              <div className={styles.step}>
                <span>Nombre:</span>
                <span>{info.nombre}</span>
              </div>
              <div className={styles.step}>
                <span>NIT:</span>
                <span>{info.nit}</span>
              </div>
              <div className={styles.step}>
                <span>NCR:</span>
                <span>{info.ncr}</span>
              </div>
              <div className={styles.step}>
                <span>Dirección:</span>
                <span>{info.direccion}</span>
              </div>
              <div className={styles.step}>
                <span>Correo Electronico:</span>
                <span>{info.email}</span>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    );
  };

  const OrderDetailTable = ({ products }) => {
    return (
      <div className="orderDetailTable">
        <Row className={cx(styles.product, styles.orderDetailTableHead)}>
          <Col span={4} className={styles.qty}>Cantidad</Col>
          <Col span={12}>Producto</Col>
          <Col span={4} className={styles.qty}>P. Unitario</Col>
          <Col span={4} className={styles.qty}>Subtotal</Col>
        </Row>
        {products.map((product) => {
            const isPromo = product.promotion;
            const details =
              !userView && !isPromo
                ? JSON.parse(product.instructions || "[]")
                : product.instructions;
            return (
              <Row className={styles.product}>
                <Col span={4}>
                  <span className={cx(styles.productName, styles.qty)}>{product.quantity}</span>
                </Col>
                <Col span={12}>
                  <span className={cx(styles.productName, styles.green)}>
                    {`${ isPromo ? product.title : product.product.name_online }`}
                  </span>
                  {isPromo ? (
                    <div className={styles.details}>
                      <div className={styles.productPromo}>
                        <span>{details.prod_1.name}</span>
                        {details.prod_1.custom.map((detail) => (
                          <span key={detail.toppingId}>
                            <span className={styles.bullet}>•</span>
                            {detail.name}
                          </span>
                        ))}
                      </div>
                      <div className={styles.productPromo}>
                        <span>{details.prod_2.name}</span>
                        {details.prod_2.custom.map((detail) => (
                          <span key={detail.toppingId}>
                            <span className={styles.bullet}>•</span>
                            {detail.name}
                          </span>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className={styles.details}>
                      {details.map((detail) => (
                        <span key={detail.toppingId}>
                          <span className={styles.bullet}>•</span>
                          {detail.name}
                        </span>
                      ))}
                    </div>
                  )}
                </Col>
                <Col span={4}>
                  <div className={cx(styles.totalProduct, styles.productName, styles.price)}>
                      <span>{currency}</span><span>{parseFloat(product.price).toFixed(2)}</span>
                  </div>
                </Col>
                <Col span={4}>
                  <div className={cx(styles.totalProduct, styles.productName, styles.price)}>
                    <span>{currency}</span><span>{parseFloat(product.subtotal).toFixed(2)}</span>
                  </div>
                </Col>
              </Row>
            );
         })
        }
      </div>
    )
  };

  return (
    <div className={styles.order}>
      <div className={styles.head}>
      <Row>
        <Col xs={24}>
          <div className={styles.header}>
            <span className={styles.title}>
              {`${
                status === 5 || status === 4
                  ? `Estatus del pedido`
                  : "Detalle del pedido"
              }:`}
              <span className={styles.green}>{` ${getStatus()}`}</span>
            </span>
            <Close action={close} />
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <div className={styles.clientInfo}>
            <Row gutter={20}>
              <Col lg={7}>
                <span className={styles.clientDetail}>
                  <span className={styles.labelClient}>CLIENTE:</span>
                  {`${Customer.name} ${Customer.lastname}`}
                </span>
              </Col>
              <Col lg={12}>
                <span className={cx(styles.clientDetail, styles.clientAddress)}>
                  <span className={styles.labelClient}>DIRECCIÓN:</span>
                  {Address.address}
                </span>
              </Col>
              <Col lg={5}>
                <span className={cx( styles.clientDetail, styles.clientPhone )}>
                  <span className={styles.labelClient}>TELÉFONO:</span>
                  {Customer.phone}
                </span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      </div>
      <div className={styles.body}>
        <Row>
          <Col xs={24}>
            <div className={styles.mainInfoWrap}>
              <span className={styles.ticket}>
                {`TICKET: ${cod_order} - DUI: ${Customer.identity}`}<br/>
                {`TELEFONO ALT: ${Customer.phone2}`}
              </span>
                <Button className={cx(styles.invoicePrintButton, styles.call)} variant="contained" onClick={() => window.print()}>IMPRIMIR</Button>
                <a href={`tel:${"+503"+Customer.phone.replace("-","")}`} className={styles.call}>LLAMAR</a>
            </div>
          </Col>
        </Row>
        
        <Row gutter={20}>
          {/* DETALLE DE LA COMPRA */}
          <Col xs={24} lg={12}>
            <div className={styles.products}>
                <OrderDetailTable key={Math.random()} products={order_details} />
            </div>
            <div className={styles.totalInfo}>
              <div className={styles.totalWrap}>
                <span className={styles.label}>SUBTOTAL</span>
                <span className={styles.price}>
                  {`${currency} ${parseFloat((total - delivery_fee)).toFixed(2)}`}
                </span>
              </div>
              <div className={styles.deliveryWrap}>
                <span className={styles.label}>COSTO DE ENVÍO</span>
                <span className={styles.price}>
                  {`${currency} ${parseFloat(delivery_fee).toFixed(2)}`}
                </span>
              </div>
              {/* 
              <div className={styles.tipWrap}>
                <span className={styles.label}>PROPINA PARA REPARTIDOR:</span>
                <span className={styles.price}>
                  {`${currency} ${parseFloat(driver_tip).toFixed(2)}`}
                </span>
              </div>
              */}
              <div className={styles.totalWrap}>
                <span className={styles.label}>TOTAL DE COMPRA</span>
                <span className={cx(styles.totalPrice, styles.green)}>
                  {`${currency} ${parseFloat(total).toFixed(2)}`}
                </span>
              </div>
            </div>

            {annotations && annotations.length > 0 && (
              <div className={styles.annotations}>
                <span>
                  <span className={styles.labelNotes}>Notas del pedido:</span>{" "}
                  {annotations}
                </span>
              </div>
            )}
          </Col>
          {/* DETALLE DE FACTURACION */}
          <Col xs={24} lg={12}>
            <Row>
              <Col xs={24}>
                <div className={styles.paymentWrap}>
                  <span className={styles.labelSection}>FORMA DE PAGO</span>
                  {order_payments.map((payment) => (
                    <div className={styles.methodWrap}>
                      <div className={styles.method}>
                        {getIconPayment(payment.name)}
                        {getNamePayment(payment)}
                      </div>
                      {getInfoPayment(payment)}
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
            {!isDriver && status == 2 && type_order=='DELIVERY' && (
              <Row>
                <Col xs={24}>
                  <div className={styles.driverWrap}>
                    <span className={styles.labelSection}>DRIVER ASIGNADO</span>
                    <div className={styles.methodWrap}>
                      <div className={styles.method}>
                        <Driver className={styles.methodIcon} />
                        <span className={cx(styles.green, styles.driverName)}>
                          {employee
                            ? `${employee.name} ${employee.lastname}`
                            : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            { monitor && !isDriver && status >= 0 && status < 2 && type_order=='DELIVERY' && (
              <Row>
                <Col xs={24}>
                  <div className={styles.selectDriverWrap}>
                    <span className={styles.labelSection}>ASIGNAR DRIVER</span>
                    <Row gutter={20}>
                      <Col xs={15} lg={17}>
                        <Select
                          className={styles.selectDriver}
                          loading={loading}
                          disabled={loading}
                          showSearch
                          size="large"
                          placeholder="Seleccionar Driver"
                          optionFilterProp="children"
                          value={selectDriver}
                          onChange={(e) => handleSelectedDriver(e)}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {drivers.map((driver) => (
                            <Option key={driver.id} value={driver.id}>
                              {`${driver.name} ${driver.lastname}`}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={9} lg={7}>
                        <Button
                          disabled={loading}
                          onClick={assingMonitorOrder}
                          loading={assing}
                          size="large"
                          type="primary"
                          className={styles.driverAssign}
                        >
                          ASIGNAR PEDIDO
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            {driverfloti == 0 && monitor && isAdmin && status === 0 && (
              <Row>
                <Col xs={24}>
                  <div className={styles.selectDriverWrap}>
                    <span className={styles.labelSection}>
                      TRANSFERIR ORDEN
                    </span>
                    <Row gutter={20}>
                      <Col xs={15} lg={17}>
                        <Select
                          className={styles.selectDriver}
                          loading={loading}
                          disabled={loading}
                          showSearch
                          size="large"
                          placeholder="Seleccionar Tienda"
                          optionFilterProp="children"
                          value={selectBranch}
                          onChange={(e) => handleSelectedBranch(e)}
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {branches.map((branch) => (
                            <Option key={branch.id} value={branch.id}>
                              {branch.name}
                            </Option>
                          ))}
                        </Select>
                      </Col>
                      <Col xs={9} lg={7}>
                        <Button
                          disabled={loading}
                          onClick={transferMonitorOrder}
                          loading={assing}
                          size="large"
                          type="primary"
                          className={styles.driverAssign}
                        >
                          TRANSFERIR
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            {invoice && getInvoice()}
            {extra_info && extra_info.length && (
              <div className={styles.reasonWrap}>
                <span className={styles.labelSection}>COMENTARIOS</span>
                <span>{extra_info}</span>
              </div>
            )}
            {driverfloti == 0 && 
            <div className={styles.reasonWrap}>
              <span className={styles.labelSection}>NOTAS DIRECCIÓN</span>
              <TextArea
                disabled={isDriver || !monitor}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
              {monitor && (
                <div className={styles.saveNotesWrap}>
                  <Button
                    disabled={loading}
                    onClick={updateNotesClient}
                    loading={updatingNotes}
                    size="large"
                    type="primary"
                    className={styles.saveNotes}
                  >
                    GUARDAR
                  </Button>
                </div>
              )}
            </div>
            }
            {status < 2 && !userView && !isDriver && Customer.orders ? (
              <Row>
                <Col xs={24}>
                  <div className={styles.historyWrap}>
                    <span className={styles.labelSection}>
                      {Customer.orders && Customer.orders.length
                        ? "COMPRA RECIENTES"
                        : "PRIMERA COMPRA"}
                    </span>
                    {Customer.orders && Customer.orders.length && (
                      <div className={styles.history}>
                        {Customer.orders.map((ordr) => (
                          <div className={styles.prevOrder}>
                            <Cart />
                            <span className={styles.date}>
                              {ordr.cod_order}
                            </span>
                            <span className={styles.date}>
                              {moment(ordr.date_created).format("DD/MM/YYYY")}
                            </span>
                            <span className={styles.address}>
                              {order.status === 5 ? <Check /> : <Cancel />}
                            </span>
                            <span className={styles.totalPrevOrder}>
                              {`${ordr.currency}${parseFloat(
                                ordr.total
                              ).toFixed(2)}`}
                            </span>
                          </div>
                        ))}
                      </div>
                    )}
                    {isAdmin && (
                      <Link
                        to={`/clientes/finales/${Customer.id}`}
                        className={styles.seeMore}
                      >
                        Ver historial completo
                      </Link>
                    )}
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <Col xs={0} lg={24}>
                  <div className={styles.stepsWrap}>
                    <span className={styles.labelSection}>
                      ESTATUS DE ORDEN
                    </span>
                    <div className={styles.steps}>
                      <div className={styles.step}>
                        <span>Recepción de orden</span>
                        <span>
                          {date_status_1
                            ? moment(date_status_1).format("hh:mm A")
                            : "-"}
                        </span>
                      </div>
                      <div className={styles.step}>
                        <span>{ type_order=='DELIVERY'?"Orden en camino":"Orden Lista" }</span>
                        <span>
                          {date_status_2
                            ? moment(date_status_2).format("hh:mm A")
                            : "-"}
                        </span>
                      </div>
                      {status === 4 ? (
                        <div className={styles.step}>
                          <span>Orden cancelada</span>
                          <span>
                            {date_status_4
                              ? moment(date_status_4).format("hh:mm A")
                              : "-"}
                          </span>
                        </div>
                      ) : (
                        <div className={styles.step}>
                          <span>Orden entregada</span>
                          <span>
                            {date_status_5
                              ? moment(date_status_5).format("hh:mm A")
                              : "-"}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            {monitor && (
              <Row>
                <Col xs={24}>
                  <div className={styles.submitWrap}>
                    {isAdmin && status <= 2 && (
                      <Button
                        size="large"
                        type="danger"
                        className={cx(styles.accept, styles.reject)}
                        onClick={cancelMonitorOrder}
                      >
                        CANCELAR PEDIDO
                      </Button>
                    )}
                    {status === 0 && (
                      <Button
                        loading={sending}
                        size="large"
                        type="primary"
                        className={styles.accept}
                        onClick={accepMonitorOrder}
                      >
                        ORDEN EN PREPARACIÓN
                      </Button>
                    )}
                    {status === 1 && (
                      <Button
                        loading={sending}
                        size="large"
                        type="primary"
                        disabled={employee==null && type_order=='DELIVERY'}
                        className={styles.accept}
                        onClick={updateMonitorOrder}
                      >
                        { type_order=='DELIVERY'? "ORDEN EN CAMINO" : "ORDEN LISTA" }
                      </Button>
                    )}
                    {status === 2 && (
                      <Button
                        loading={sending}
                        onClick={finishMonitorOrder}
                        size="large"
                        type="primary"
                        className={styles.accept}
                      >
                        FINALIZAR PEDIDO
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
      <div className={styles.printContent}>
        <Row gutter={20}>
          {/* INFORMACION DE CLIENTE */}
          <Col xs={24} lg={12}>
          <div className={styles.header}>
            <span className={styles.title}>
              Detalle del Pedido # {order.cod_order}
            </span>
          </div>
          <div className={styles.clientInfo}>
            
                <div >
                  <span className={styles.labelClient}>CLIENTE:</span>
                  {`${Customer.name} ${Customer.lastname}`}
                </div>

                <div >
                  <span className={styles.labelClient}>DUI:</span>
                  {Customer.identity}
                </div>

                <div>
                  <span className={styles.labelClient}>CORREO:</span>
                  {Customer.emailguest?Customer.emailguest:Customer.email}
                </div>

                <div>
                  <span className={styles.labelClient}>TELÉFONO:</span>
                  {Customer.phone}
                </div>

                <div>
                  <span className={styles.labelClient}>TELÉFONO 2:</span>
                  {Customer.phone2}
                </div>
              
                <div>
                  <span className={styles.labelClient}>DIRECCIÓN:</span>
                  {Address.address}
                </div>
          </div>
          </Col>
          {/* DETALLE DE LA COMPRA */}
          <Col xs={24} lg={12}>
            <div className={styles.products}>
                <OrderDetailTable key={Math.random()} products={order_details} />
            </div>
            <div className={styles.totalInfo}>
              <div className={styles.totalWrap}>
                <span className={styles.label}>SUBTOTAL</span>
                <span className={styles.price}>
                  {`${currency} ${parseFloat((total - delivery_fee)).toFixed(2)}`}
                </span>
              </div>
              <div className={styles.deliveryWrap}>
                <span className={styles.label}>COSTO DE ENVÍO</span>
                <span className={styles.price}>
                  {`${currency} ${parseFloat(delivery_fee).toFixed(2)}`}
                </span>
              </div>
              {/* 
              <div className={styles.tipWrap}>
                <span className={styles.label}>PROPINA PARA REPARTIDOR:</span>
                <span className={styles.price}>
                  {`${currency} ${parseFloat(driver_tip).toFixed(2)}`}
                </span>
              </div>
              */}
              <div className={styles.totalWrap}>
                <span className={styles.label}>TOTAL DE COMPRA</span>
                <span className={cx(styles.totalPrice, styles.green)}>
                  {`${currency} ${parseFloat(total).toFixed(2)}`}
                </span>
              </div>
            </div>

            {annotations && annotations.length > 0 && (
              <div className={styles.annotations}>
                <span>
                  <span className={styles.labelNotes}>Notas del pedido:</span>{" "}
                  {annotations}
                </span>
              </div>
            )}
          </Col>
          {/* DETALLE DE PAGO */}
          <Col xs={24}>
            <div className={styles.paymentWrap}>
              <span className={styles.labelSection}>FORMA DE PAGO</span>
              {order_payments.map((payment) => (
                <div className={styles.methodWrap}>
                  <div className={styles.method}>
                    {getIconPayment(payment.name)}
                    {getNamePayment(payment)}
                  </div>
                  {getInfoPayment(payment)}
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default OrderDetail;
