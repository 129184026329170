import { useEffect, useState } from 'react';
import { Row, Col, Tabs, Switch, Modal, Spin, Input, Button, Space, Table, Tag } from "antd";
import { Link } from "react-router-dom";
import cx from "classnames";
import moment from "moment";
import ProductCard from "./ProductCard";
import ConfirmDriver from "./ConfirmDriver";
import Commentaries from "./Commentaries";
import TableOrders from "../../../components/TableOrders";
import OrderDetail from "../../../components/OrderDetail";
import { ReactComponent as ClockRefresh } from "../../../assets/icons/clock-refresh.svg";
import { ReactComponent as Empty } from "../../../assets/icons/empty.svg";
import { ReactComponent as CardIcon } from "../../../assets/icons/card2.svg";
import { ReactComponent as CashIcon } from "../../../assets/icons/cash2.svg";
import { ReactComponent as ManUser } from "../../../assets/icons/man-user.svg";
import Procesando from "../../../assets/icons/Procesando.svg";
import Check from "../../../assets/icons/check-2.svg";
import TotalOrdenes from "../../../assets/icons/total-orders.svg";
import Ventas from "../../../assets/icons/factura.svg";
import Delivery from "../../../assets/icons/delivery-man-aldi.svg";
import Ingresos from "../../../assets/icons/ingresos.svg";
import { ReactComponent as Reporte } from "../../../assets/icons/reporte.svg";
import styles from "../styles/MonitorUI.module.css";

const { TabPane } = Tabs;

const MonitorUI = (props) => {
  const {
    modalOrder,
    closeDetails,
    openDetails,
    loaded,
    orderDetail,
    isAdmin,
    statistics,
    newOrders,
    dayOrders,
    branches,
    isDriver,
    acceptOrder,
    updateOrder,
    assignOrder,
    finishOrder,
    cancelOrder,
    getDriversBranch,
    handleDelivery,
    modalDriverConfirm,
    closeDriverConfirm,
    openDriverConfirm,
    closeModalCommetaries,
    openModalCommetaries,
    modalCommentaries,
    transferOrder,
    loyalty,
    newValueLoyalty,
    newActiveLoyalty,
    changeLoyaltyInfo,
    sending,
    updateNotes,
    tipAvailable,
    handleTipAvailable,
    sendingTip,
    setScheduleVisible
  } = props;

  const statusNames = ['Recibida','Procesando','En camino', 'Modificada', 'Anulada', 'Finalizada'];
  const [infoStat, setinfoStat] = useState(statistics.day);
  const [typeKey, setTypeKey] = useState("day");

  const Card = ({ label, value, value2 }) => (
    <div className={styles.card}>
      <span className={styles.labelCard}>{label}</span>
      <div className={styles.valueCard}>
        <span className={cx(styles.value1, { [styles.numberOrders]: !value2 })}>
          {value}
        </span>
        {value2 && (
          <span className={styles.value2}>
            {value2} <span className={styles.wtx}>(sin IVA)</span>
          </span>
        )}
      </div>
    </div>
  );

  const initialFetch = () => {
    const key = typeKey;
    if(key === "day") {
      setinfoStat(statistics.day);
    }
    if(key === "yesterday") {
      setinfoStat(statistics.yesterday);
    }
    if(key === "week") {
      setinfoStat(statistics.week);
    }
    if(key === "month") {
      setinfoStat(statistics.month);
    }
};

useEffect(() => {
initialFetch();
}, [loaded, typeKey]);

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'date_created',
      render: val => moment(val).fromNow(),
    },
    {
      title: 'Pedido',
      dataIndex: 'cod_order',
      render: (_, text) => {
        return (
          <div>
            <div>
              <ManUser />
              <span> #{text.cod_order.substring(3)}</span>
            </div>
            <div>{text.Customer.name} {text.Customer.lastname}</div>
          </div>
        );
      }
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      align: 'center',
      render: (status) => {
          let color = status >= 5 ? 'geekblue' : 'green';
          if (status == 5) {
            color = 'green';
          }else if (status == 1){
            color = 'geekblue';
          }else{
            color ='yellow';
          }
          return (
            <Tag color={color} key={status}>
              {statusNames[status].toUpperCase()}
            </Tag>
          );
      },
    },
    {
      title: 'Envío a',
      dataIndex: 'id',
      render: (_, record) => {
        return (
            <span>{record.Address.address}</span>
        );
      },
    },
    {
      title: 'Método de pago',
      dataIndex: 'id',
      render: (_, record) => {
        const payment = record.order_payments[0];
        return (
          <div className={styles.paymentMethod}>
            {payment.name=='Pago Efectivo'?<CashIcon />:<CardIcon />}
            <span>{payment.name}</span>
          </div>
        );
        
      },
    },
    {
      title: 'Monto',
      dataIndex: 'total',
      render: (text) => {
        return (
          <p className={styles.amount}>$ {Number(text).toFixed(2)}</p>
        )
      }
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      render: (text, order) => {
        return (
          <div className='actions-order'>
            <div><a href="#" onClick={() => {openDetails(order); return false;} }><img src={Procesando} alt="Ver detalle" /></a></div>
          </div>
        )
      }
    },
  ];

  const filterData = (key, event) => {
    console.log(key);
    console.log(event);

    setTypeKey(key);
  };

  if (!loaded) {
    return (
      <div className={cx(styles.view, styles.loader)}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className={styles.view}>
      {!isDriver ? (
        <>
          <Row gutter={40}>
            <Col xs={0} lg={24}>
              <div className={styles.tabsSummaryWrap}>
                <Tabs defaultActiveKey="day" onTabClick={filterData}>
                  <TabPane tab="ESTE DÍA" key="day"></TabPane>
                  <TabPane tab="AYER" key="yesterday"></TabPane>
                  <TabPane tab="ESTE SEMANA" key="week"></TabPane>
                  <TabPane tab="ESTE MES" key="month"></TabPane>
                </Tabs>
              </div>
            </Col>
            <Col xs={0} lg={24}>
              <div className={styles.sectionKPI}>
                <div className={styles.kpi}>
                  <img src={TotalOrdenes} alt="Ordenes" />
                  <h3 className={styles.totalOrdersNum}>{infoStat.orders}</h3>
                  <p className={styles.subTitle}>total de ordenes</p>
                  <div className={styles.kpiFooter}>
                    {/* <a href="#"><Reporte /> <span>Ver Reporte</span></a> */}
                  </div>
                </div>
                <div className={styles.kpi}>
                <img src={Ventas} alt="Ventas" />
                  <h3 className={styles.ventasNum}>{infoStat.total}</h3>
                  <p className={styles.subTitle}>ventas</p>
                  <div className={styles.kpiFooter}>
                    {/* <Reporte /> <span>Ver Reporte</span> */}
                  </div>
                </div>
                <div className={styles.kpi}>
                  <img src={Delivery} alt="Costo de envio" />
                  <h3 className={styles.costosNum}>$0.00</h3>
                  <p className={styles.subTitle}>costo de envio</p>
                  <div className={styles.kpiFooter}>
                   {/*  <Reporte /> <span>Ver Reporte</span> */}
                  </div>
                </div>
                <div className={styles.kpi}>
                  <img src={Ingresos} alt="Ingresos" />
                  <h3 className={styles.ingresosNum}>{infoStat.total_wtx}</h3>
                  <p className={styles.subTitle}>ingresos</p>
                  <div className={styles.kpiFooter}>
                    {/* <Reporte /> <span>Ver Reporte</span> */}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={0} lg={24}>
              <div className={styles.newOrdersTable}>
                <div className={styles.header}>
                  <span>Órdenes pendientes { newOrders.length > 0? "["+ newOrders.length+"]":"" }</span>
                </div>
                { newOrders.length > 0 ? (
                  <div className={styles.body}>
                    <div className={styles.body}>
                      <Table columns={columns} dataSource={newOrders} />
                    </div>
                  </div>
                    /* 
                    <div className={styles.newOrders}>
                      {newOrders.map((order) => (
                        <div
                          key={order.id}
                          className={cx(styles.row, styles.tableItem)}
                          onClick={() => openDetails(order)}
                        >
                          <span className={styles.cellTicket}>
                            {order.cod_order}
                          </span>
                          <span className={styles.cellCostumer}>
                            {`${order.Customer.name} ${order.Customer.lastname}`}
                          </span>
                          <span className={styles.cellTime}>
                            {moment(order.date_created).format("hh:mma")}
                          </span>
                          <span
                            className={cx(styles.cellAmount, styles.amount)}
                          >
                            {order.currency}
                            {parseFloat(order.total).toFixed(2)}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  */
                ) : (
                  <div className={styles.wrapEmptyNewOrders}>
                    <span>Sin ordenes recientes</span>
                  </div>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={24} lg={0}>
              <div className={styles.tabsSummaryWrap}>
                <Tabs defaultActiveKey="1">
                  <TabPane tab={`NUEVAS (${newOrders.length})`} key="1">
                    {newOrders.length ? (
                      newOrders.map((order) => (
                        <ProductCard
                          key={order.id}
                          order={order}
                          openDetails={openDetails}
                        />
                      ))
                    ) : (
                      <div className={styles.empty}>
                        <Empty />
                        <span>SIN ORDENES NUEVAS</span>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tab={`ORDENES (${dayOrders.length})`} key="2">
                    {dayOrders.length ? (
                      <TableOrders
                        monitor
                        orders={dayOrders}
                        openDetails={openDetails}
                      />
                    ) : (
                      <div className={styles.empty}>
                        <Empty />
                        <span>NO SE HAN ENCONTRADO ORDENES</span>
                      </div>
                    )}
                  </TabPane>
                  <TabPane tab="REPORTE" key="3">
                    <Card label="PEDIDOS" value={statistics?.day?.orders} />
                    <Card
                      label="VENTAS"
                      value={`$${parseFloat(statistics?.day?.total).toFixed(
                        2
                      )}`}
                      value2={`$${parseFloat(
                        statistics?.day?.total_wtx
                      ).toFixed(2)}`}
                    />
                  </TabPane>
                </Tabs>
              </div>
            </Col>
          </Row>
        </>
      ) : (
        <Row>
          <Col span={24}>
            <div className={styles.tabsSummaryWrap}>
              <Tabs defaultActiveKey="1">
                <TabPane tab="ORDENES ACTIVAS" key="1">
                  {newOrders.length ? (
                    newOrders.map((order) => (
                      <ProductCard
                        key={order.id}
                        order={order}
                        openDetails={openDetails}
                      />
                    ))
                  ) : (
                    <div className={styles.empty}>
                      <Empty />
                      <span>SIN ORDENES NUEVAS</span>
                    </div>
                  )}
                </TabPane>
                <TabPane tab="HISTORIAL" key="2">
                  {dayOrders.length ? (
                    <TableOrders
                      monitor
                      orders={dayOrders}
                      openDetails={openDetails}
                    />
                  ) : (
                    <div className={styles.empty}>
                      <Empty />
                      <span>NO SE HAN ENCONTRADO ORDENES</span>
                    </div>
                  )}
                </TabPane>
              </Tabs>
            </div>
          </Col>
        </Row>
      )}
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalDriverConfirm}
        width={400}
        closable={false}
        onCancel={closeDriverConfirm}
      >
        <ConfirmDriver close={closeDriverConfirm} updateOrder={updateOrder} />
      </Modal>
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalCommentaries}
        width={400}
        closable={false}
        onCancel={closeModalCommetaries}
      >
        <Commentaries
          close={closeModalCommetaries}
          finishOrder={finishOrder}
          cancelOrder={cancelOrder}
          type={modalCommentaries}
        />
      </Modal>
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={modalOrder}
        width={1150}
        closable={false}
        onCancel={closeDetails}
      >
        <OrderDetail
          order={orderDetail}
          monitor
          close={closeDetails}
          branches={branches}
          acceptOrder={acceptOrder}
          updateOrder={updateOrder}
          assignOrder={assignOrder}
          getDriversBranch={getDriversBranch}
          openDriverConfirm={openDriverConfirm}
          openModalCommetaries={openModalCommetaries}
          transferOrder={transferOrder}
          updateNotes={updateNotes}
        />
      </Modal>
    </div>
  );
};

export default MonitorUI;
