import instance from './Request';

export const getUsers = async () => {
  const request = await instance();
  let data = await request
    .get('/employee')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const getRoles = async () => {
  const request = await instance();
  let data = await request
    .get('/employee/roles')
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const newUser = async user => {
  const request = await instance();
  let data = await request
    .post('/employee', user)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const changePassword = async (user, password) => {
  const request = await instance();
  let data = await request
    .post('/main/change-password', {user, newPass: password})
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const editUser = async user => {
  const request = await instance();
  let data = await request
    .put(`/employee/${user.id}`, user)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};

export const deleteUser = async id => {
  const request = await instance();
  let data = await request
    .delete(`/employee/${id}`)
    .catch((error) => {
      return {
        error,
      };
    });
  return data;
};
