import {useState, useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  message,
} from 'antd';
import {useDispatch} from 'react-redux';
import cx from 'classnames';
import {createUser, updateUser} from '../../../redux/slices/users';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';

const {Option} = Select;

const Editor = ({close, user, branches, handleUser, vehicles, openModalPassword, roles}) => {
  const [sending, handleSending] = useState(false);
  const [isDriver, handleIsDriver] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (user) {
        const newValues = {
          name: user.name,
          lastname: user.lastname,
          email: user.email,
          phone: user.phone,
          role: user.user.roleId,
          user: user.user.username,
          identity: user.identity,
          location: user.locationId,
          vehicle: user.vehicleId,
        };
        if (user.user.roleId === 4) {
          handleIsDriver(true);
        }
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [user]);

  const validateDriver = e => {
    if (e === 4) {
      handleIsDriver(true);
    } else {
      handleIsDriver(false);
    }
  };

  const handlePhone = (e) => {
    const maxLength = 8
    let value = e.target.value.replace(/\D/g, '')
    value = value.slice(0, maxLength)
  
    if (value.length > 4) {
      value = value.replace(/^(\d{4})/, '$1-')
    }
    e.target.value = value
  }

  const handleDui = (e) => {
    const maxLength = 9
    let value = e.target.value.replace(/\D/g, '')
    value = value.slice(0, maxLength)
  
    if (value.length > 8) {
      value = value.replace(/^(\d{8})/, '$1-')
    }
    e.target.value = value
  }

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      const info = {
        nombre: values.name,
        apellido: values.lastname,
        correo: values.email,
        telefono: values.phone,
        rol: values.role,
        identidad: values.identity,
        tienda: values.location,
        usuario: values.user,
      };
      if (user) {
        info.id = user.id;
        info.pswd = user.user.password;
      } else {
        info.pswd = values.password;
      }
      if (isDriver) {
        info.placa = values.vehicle;
      }
      if (user) {
        const response = await dispatch(updateUser(info));
        if (response.status === 'success') {
          handleSending(false);
          message.success('Cambios realizados con éxito');
        } else if (response.type === 'user-found') {
          handleSending(false);
          message.warning('Este usuario no esta disponible');
        } else {
          handleSending(false);
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      } else {
        const response = await dispatch(createUser(info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
          handleSending(false);
          handleUser(response.user);
        } else if (response.type === 'user-found') {
          handleSending(false);
          message.warning('Este usuario no esta disponible');
        } else {
          handleSending(false);
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      }
    } catch (e) {
      console.log(e)
    }
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {user ? 'Editar usuario' : 'Nuevo usuario'}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      > 
        <Row gutter={20}>
          <Col span={24}>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      NOMBRE
                    </span>
                  }
                  name="name"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar nombre'}]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      APELLIDO
                    </span>
                  }
                  name="lastname"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar apellido'}]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      TELÉFONO
                    </span>
                  }
                  name="phone"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar teléfono'}]}
                >
                  <Input size="large" className={styles.input} onInput={handlePhone} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      CORREO
                    </span>
                  }
                  name="email"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar correo'}]}
                >
                  <Input size="large" type="email" className={styles.input} />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      NÚMERO DE DOCUMENTO
                    </span>
                  }
                  name="identity"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar número de documento'}]}
                >
                  <Input size="large" className={styles.input}  onInput={handleDui} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      ROL
                    </span>
                  }
                  name="role"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar rol'}]}
                >
                  <Select size="large" className={styles.input} onChange={validateDriver}>
                    {roles.map(role => (
                      <Option key={role.id} value={role.id}>
                        {role.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={isDriver ? 12 : 24}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      TIENDA
                    </span>
                  }
                  name="location"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar tienda'}]}
                >
                  <Select size="large" className={styles.input}>
                    {branches.map(branch => (
                      <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {isDriver && (
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className={styles.label}>
                        MOTO
                      </span>
                    }
                    name="vehicle"
                    className={styles.itemColumn}
                    rules={[{required: true, message: 'Ingresar tienda'}]}
                  >
                    <Select size="large" className={styles.input}>
                      {vehicles.map(vehicle => (
                        <Option key={vehicle.id} value={vehicle.id}>{vehicle.plate}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            <Row gutter={20}>
              <Col span={user ? 24 : 12}>
                <Form.Item
                  label={
                    <span className={styles.label}>
                      USUARIO
                    </span>
                  }
                  name="user"
                  className={styles.itemColumn}
                  rules={[{required: true, message: 'Ingresar usuario'}]}
                >
                  <Input size="large" className={styles.input} />
                </Form.Item>
              </Col>
              {!user && (
                <Col span={12}>
                  <Form.Item
                    label={
                      <span className={styles.label}>
                        CONTRASEÑA
                      </span>
                    }
                    name="password"
                    className={styles.itemColumn}
                    rules={[{required: true, message: 'Ingresar contraseña'}]}
                  >
                    <Input size="large" className={styles.input} />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              {user && (
                <Button
                  className={cx(styles.submit, styles.password)}
                  onClick={openModalPassword}
                  size="large"
                  type="primary">
                  CAMBIAR CONTRASEÑA
                </Button>
              )}
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit">
                GUARDAR USUARIO
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Editor;
