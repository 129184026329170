import {useState, useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  message,
  Upload,
} from 'antd';
import {useDispatch} from 'react-redux';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {createCategory, updateCategory} from '../../../redux/slices/categories';
import {normFile, beforeUpload, dummyRequest} from '../../../utils/images';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';

const {Option} = Select;

const Editor = ({close, category, branch, handleCategory, types}) => {
  const [sending, handleSending] = useState(false);
  const [content, handleContent] = useState('');
  const [extra_c, handleExtra_c] = useState('');
  const [extra_m, handleExtra_m] = useState('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (category) {
        const newValues = {
          name: category.name,
          type: category.type,
          description: category.description,
          miniDescription: category.mini_description,
          slug: category.slug,
          videoLink: category.banner_image,
          info_extra: category.info_extra,
          info_extra2: category.info_extra2
        };
        console.log(category);
        handleContent(category.description);
        handleExtra_c(category.info_extra);
        handleExtra_m(category.info_extra2);
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, [category]);

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      const info = new FormData();
      if (category) {
        info.append('id', category.id);
      }
      info.append('locationId', branch);
      info.append('name', values.name);
      info.append('description', content);
      info.append('mini_description', values.miniDescription);
      info.append('slug', values.slug);
      //info.append('banner_image', values.videoLink);
      info.append('type', values.type);
      // info.append('info_extra', extra_c);
      // info.append('info_extra2', extra_m);
      info.append('icon_image', values.iconImage && values.iconImage.length ? values.iconImage[0]?.originFileObj : (category ? category.icon_image : ''));
      info.append('image', values.image && values.image.length ? values.image[0]?.originFileObj : (category ? category.image : ''));
      info.append('banner_image', values.imageBanner && values.imageBanner.length ? values.imageBanner[0]?.originFileObj : (category ? category.banner_image : ''));

      if (category) {
        const response = await dispatch(updateCategory(category.id, info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
        } else if (response.type === 'incomplete') {
          handleSending(false);
          message.warning('Ingresa todos los datos e imagenes solicitadas');
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      } else {
        const response = await dispatch(createCategory(info));
        console.log(response);
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
          handleCategory(response.category);
        } else if (response.type === 'incomplete') {
          handleSending(false);
          message.warning('Ingresa todos los datos e imagenes solicitadas');
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      }
      handleSending(false);
    } catch (e) {
      message.error('¡Hubo un problema! Inténtalo de nuevo');
    }
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {category ? 'Editar categoría' : 'Nueva categoría'}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="category-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
        className={styles.form}
      >
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  NOMBRE
                </span>
              }
              name="name"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar nombre'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  TIPO DE CATEGORÍA
                </span>
              }
              name="type"
              className={styles.itemColumn}
              rules={[{required: false, message: 'Ingresar tipo'}]}
            >
              <Select size="large" className={styles.input}>
                <Option key="1" value="1">Pruebas</Option>
                {types.map(type => (
                  <Option key={type.id} value={type.id}>{type.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  DESCRIPCIÓN CORTA
                </span>
              }
              name="miniDescription"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar descripción corta'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              label={
                <span className={styles.label}>
                  DESCRIPCIÓN
                </span>
              }
              name="description"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar descripción'}]}
            >
              <ReactQuill theme="snow" className={styles.input} value={content} onChange={handleContent}/>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  SLUG
                </span>
              }
              name="slug"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar slug'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
          <Col span={12}>
          <Form.Item
              label={
                <span className={styles.label}>
                  ENLACE DE VIDEO
                </span>
              }
              name="videoLink"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar enlace de video'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              name="iconImage"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className={styles.itemColumn}
              label={
                <span className={styles.label}>
                  ÍCONO PRINCIPAL
                </span>
              }
              extra={
                <span className={styles.center}>
                  (Formatos jpg/png de 128x100px)
                </span>
            }>
              <Upload
                maxCount={1}
                customRequest={e => dummyRequest(e, () => {})}
                beforeUpload={beforeUpload}
                listType="picture">
                <Button className={styles.uploadImage}>
                  Seleccionar
                </Button>
              </Upload>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                className={styles.itemColumn}
                label={
                  <span className={styles.label}>
                    IMAGEN DETALLE
                  </span>
                }
                extra={
                  <span className={styles.center}>
                    (Formatos jpg/png de 28x46px)
                  </span>
              }>
                <Upload
                  maxCount={1}
                  customRequest={e => dummyRequest(e, () => {})}
                  beforeUpload={beforeUpload}
                  listType="picture">
                  <Button className={styles.uploadImage}>
                    Seleccionar
                  </Button>
                </Upload>
              </Form.Item>
            
            <Form.Item
              name="imageBanner"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className={styles.itemColumn}
              label={
                <span className={styles.label}>
                  IMÁGEN PARA BANNER
                </span>
              }
              extra={
                <span className={styles.center}>
                  (Formatos jpg/png de 1032x220px)
                </span>
            }>
              <Upload
                maxCount={1}
                customRequest={e => dummyRequest(e, () => {})}
                beforeUpload={beforeUpload}
                listType="picture">
                <Button className={styles.uploadImage}>
                  Seleccionar
                </Button>
              </Upload>
            </Form.Item>
            
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit">
                GUARDAR CATEGORÍA
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Editor;
