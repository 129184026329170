import {Button, Table, Spin, Modal} from 'antd';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import Password from './Password';
import styles from '../styles/UsersUI.module.css';

const UsersUI = props => {
  const {
    loading,
    users,
    user,
    branches,
    openEditor,
    editorVisible,
    closeEditor,
    newUser,
    confirmDelete,
    handleUser,
    vehicles,
    modalPassword,
    openModalPassword,
    closeModalPassword,
    changePassword,
    roles,
  } = props;

  const getLocationName = location => {
    let name = '-';
    const index = branches.findIndex(el => el.id === location);
    if (index > -1) {
      name = branches[index].name;
    }
    return name;
  };

  const getRoleName = role => {
    const index = roles.findIndex(el => el.id === role);
    if (index > -1) {
      return roles[index].name;
    }
    return '-';
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Apellidos',
      dataIndex: 'lastname',
      ...getColumnSearchProps('lastname'),
    },
    {
      title: 'Tienda',
      dataIndex: 'locationId',
      align: 'center',
      render: val => getLocationName(val),
    },
    {
      title: 'Rol',
      dataIndex: 'user',
      align: 'center',
      filters: roles.map(role => ({
        text: role.name,
        value: role.id,
      })),
      onFilter: (val, record) => record.user.roleId === val,
      render: val => getRoleName(val.roleId),
    },
    {
      title: 'Editar',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className={styles.view}>
      <Header
        back="/ajustes"
        title="Usuarios"
        actions={
          <Button
            onClick={newUser}
            type="primary"
            size="large"
            className="main-button">
            AGREGAR USUARIO
          </Button>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={users}
            rowKey="id"
          />
        </div>
      )}
      <Modal
        destroyOnClose
        footer={false}
        visible={modalPassword}
        width={400}
        closable={false}
        onCancel={closeModalPassword}>
        <Password
          close={closeModalPassword}
          changePassword={changePassword}
        />
      </Modal>
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor
          close={closeEditor}
          user={user}
          branches={branches}
          handleUser={handleUser}
          vehicles={vehicles}
          openModalPassword={openModalPassword}
          roles={roles}
        />
      </Modal>
    </div>
  );
};

export default UsersUI;
