import {Link} from 'react-router-dom';
import {ReactComponent as Arrow} from '../assets/icons/arrow-left.svg';
import styles from './styles/HeaderList.module.css';

const HeaderList = ({title, back, actions}) => (
  <div className={styles.header}>
    <div className={styles.titleWrap}>
      {back && (
        <Link to={back} className={styles.backWrap}>
          <Arrow />
        </Link>
      )}
      <span className={styles.title}>{title}</span>
    </div>
    {actions}
  </div>
);

export default HeaderList;