import {createSlice} from '@reduxjs/toolkit';
import {getVehicles, newVehicle, editVehicle, deleteVehicle} from '../../api/Vehicles';

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: {
    vehicles: [],
  },
  reducers: {
    setVehicles: (state, action) => {
      state.vehicles = action.payload;
    },
    setVehicle: (state, action) => {
      const index = state.vehicles.findIndex(el => el.id === action.payload.id);
      if (index > -1) {
        state.vehicles[index] = action.payload;
      }
    },
    addVehicle: (state, action) => {
      state.vehicles.push(action.payload);
    },
    removeVehicle: (state, action) => {
      const index = state.vehicles.findIndex(el => el.id === action.payload);
      if (index > -1) {
        state.vehicles.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {setVehicles, setVehicle, addVehicle, removeVehicle} = vehiclesSlice.actions;

export const getAllVehicles = () => async dispatch => {
  try {
    const response = await getVehicles();
    if (!response.error && response.status === 200) {
      dispatch(setVehicles(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const createVehicle = data => async dispatch => {
  try {
    const response = await newVehicle(data);
    if (!response.error && response.status === 200) {
      dispatch(addVehicle(response.data));
      return {
        status: 'success',
        vehicle: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const updateVehicle = data => async dispatch => {
  try {
    const response = await editVehicle(data);
    if (!response.error && response.status === 200) {
      dispatch(setVehicle(response.data));
      return {
        status: 'success',
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const removeMonitorVehicle = id => async dispatch => {
  try {
    const response = await deleteVehicle(id);
    if (!response.error && response.status === 200) {
      dispatch(removeVehicle(id));
      return {
        status: 'success',
        // departments: response.data,
      };
    }
    return {
      status: 'error',
      type: 'unkown'
    };
  } catch (e) {
    return {
      status: 'error',
      type: 'unknown',
    };
  }
};

export const selectVehicles = state => state.vehicles.vehicles;

export default vehiclesSlice.reducer;
