import {useState} from 'react';
import {Button, Input} from 'antd';
import Close from '../../../components/Close';
import styles from '../styles/Commentaries.module.css';

const Commentaries = ({close, type, cancelOrder, finishOrder}) => {
  const [sending, handleSending] = useState(false);
  const [commentaries, handleCommentaries] = useState('');
  const isCancel = type === 'cancel';

  const finishMonitorOrder = async () => {
    handleSending(true);
    await finishOrder(commentaries);
    handleSending(false);
  };

  const cancelMonitorOrder = async () => {
    handleSending(true);
    await cancelOrder(commentaries);
    handleSending(false);
  };


  return (
    <div>
      <div className={styles.header}>
        <span className={styles.title}>
          {`${isCancel ? 'Cancelar' : 'Finalizar'} pedido`}
        </span>
        <Close action={close} />
      </div>
      <div className={styles.body}>
        <Input.TextArea
          rows={4}
          className={styles.input}
          placeholder="Comentarios"
          value={commentaries}
          onChange={e => handleCommentaries(e.target.value)}
        />
      </div>
      <div className={styles.action}>
        <Button
          loading={sending}
          onClick={isCancel ? cancelMonitorOrder : finishMonitorOrder}
          size="large"
          type={isCancel ? 'danger' : 'primary'}
          className={styles.submit}>
          {`${isCancel ? 'CANCELAR' : 'FINALIZAR'} PEDIDO`}
        </Button>
      </div>
    </div>
  );
};

export default Commentaries;
