import {useState, useEffect} from 'react';
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Select,
  TreeSelect,
  message,
} from 'antd';
import {useDispatch} from 'react-redux';
import {createZone, updateZone} from '../../../redux/slices/zones';
import {validateFloatKeyPress} from '../../../utils/format';
import Close from '../../../components/Close';
import styles from '../styles/Editor.module.css';
import { parseInt } from 'lodash';

const {Option} = Select;
const {TreeNode} = TreeSelect;

const Editor = ({close, zone, departments, levels, branches, handleZone}) => {
  const [sending, handleSending] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const initialFetch = async () => {
      if (zone) {
        const newValues = {
          name: zone.name,
          locality: `${zone.locality.department}-${zone.locality.id}`,
          location: zone.locationId,
          level: zone.level,
          delivery_fee: zone.delivery_fee,
        };
        form.setFieldsValue(newValues);
      }
    };
    initialFetch();
  }, []);

  const getDepartments = () => (
    departments.map(department => (
      <TreeNode
        key={department.id}
        selectable={false}
        value={department.id}
        title={department.label}
      >
        {department.items.map(locality => (
          <TreeNode
            key={`${department.id}-${locality.id}`}
            value={`${department.id}-${locality.id}`}
            title={locality.name}
          />
        ))}
      </TreeNode>
    ))
  );

  const save = async () => {
    try {
      handleSending(true);
      const values = await form.validateFields();
      const locality = values.locality.split('-');
      const info = {
        level: values.level,
        description: values.name,
        name: values.name,
        locationId: values.location,
        localityId: parseInt(locality[1], 10),
        delivery_fee: parseFloat(values.delivery_fee),
      };
      if (zone) {
        info.id = zone.id;
      }
      if (zone) {
        const response = await dispatch(updateZone(info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      } else {
        const response = await dispatch(createZone(info));
        if (response.status === 'success') {
          message.success('Cambios realizados con éxito');
          handleZone(response.zone);
        } else {
          message.error('¡Hubo un problema! Inténtalo de nuevo');
        }
      }
      handleSending(false);
    } catch (e) {
    }
  };

  return (
    <div className={styles.editor}>
      <div className={styles.header}>
        <span className={styles.title}>
          {zone ? 'Editar cobertura' : 'Nueva cobertura'}
        </span>
        <Close action={close} />
      </div>
      <Form
        name="branch-editor"
        autoComplete="off"
        form={form}
        colon={false}
        requiredMark={false}
        onFinish={save}
      >
        <Row>
          <Col span={24}>
            <Form.Item
              className={styles.itemColumn}
              label={
                <span className={styles.label}>
                  MUNICIPIO
                </span>
              }
              rules={[{required: true, message: 'Ingresar municipio'}]}
              name="locality">
              <TreeSelect
                showSearch
                className={styles.input}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                treeDefaultExpandAll
                size="large"
                filterTreeNode={(search, item) => {
                  return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                }}
              >
                {getDepartments()}
              </TreeSelect>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  DOMICILIO
                </span>
              }
              name="location"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar domicilio'}]}
            >
              <Select size="large" className={styles.input}>
                {branches.map(branch => (
                  <Option key={branch.id} value={branch.id}>{branch.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  TARIFA
                </span>
              }
              name="delivery_fee"
              normalize={validateFloatKeyPress}
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar tarifa'}]}
            >
              <Input size="large" className={styles.input} prefix="$ " />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  TIPO DE COBERTURA
                </span>
              }
              name="level"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar tipo'}]}
            >
              <Select size="large" className={styles.input}>
                {levels.map(level => (
                  <Option key={level.id} value={level.id}>{level.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={
                <span className={styles.label}>
                  NOMBRE
                </span>
              }
              name="name"
              className={styles.itemColumn}
              rules={[{required: true, message: 'Ingresar nombre'}]}
            >
              <Input size="large" className={styles.input} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item className={styles.itemSubmit}>
              <Button
                loading={sending}
                className={styles.submit}
                size="large"
                type="primary"
                htmlType="submit">
                GUARDAR COBERTURA
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default Editor;
