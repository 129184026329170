import { TreeSelect } from "antd";
import Styles from "./styles/LocationSelector.module.css";

const LocationSelector = (props) => {

    const { dataSource, selectedValues, setSelectedValues } = props;
 
    const listValues = dataSource.map( (item) => ({
        title: item.name,
        value: item.id
    }));

    const allIds = listValues.map(({ value }) => value);

    return (
      <TreeSelect
        allowClear={true}
        choiceTransitionName={Styles.TreeSelect}
        placeholder="Todas las Sucursales"
        treeCheckable={true}
        showCheckedStrategy={TreeSelect.SHOW_CHILD}
        style={{ width: "350px" }}
        dropdownStyle={{ maxHeight: "300px" }}
        onChange={ids => setSelectedValues(ids)}
        value={selectedValues}
        treeData={[
          {
            title:
              selectedValues.length === listValues.length ? (
                <span
                  onClick={() => setSelectedValues([])}
                  style={{
                    display: "inline-block",
                    color: "#286FBE",
                    cursor: "pointer"
                  }}
                >
                  Quitar Todas
                </span>
              ) : (
                <span
                  onClick={() => setSelectedValues(allIds)}
                  style={{
                    display: "inline-block",
                    color: "#286FBE",
                    cursor: "pointer"
                  }}
                >
                  Seleccionar Todas
                </span>
              ),
            value: "xxx",
            disableCheckbox: true,
            disabled: true
          },
          ...listValues
        ]}
      />
    );
  };
  

  export default LocationSelector;