import { useEffect, useState } from 'react';
import {Button, Table, Spin, Modal, Select, Switch} from 'antd';
import moment from 'moment';
import dragula from 'dragula';
import Header from '../../../components/HeaderList';
import {ReactComponent as Edit} from '../../../assets/icons/edit.svg';
import {ReactComponent as Trash} from '../../../assets/icons/trash.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import Editor from './Editor';
import "dragula/dist/dragula.css";
import styles from '../styles/BrandsUI.module.css';


const {Option} = Select;

const BrandsUI = props => {
  const {
    loading,
    brands,
    brand,
    openEditor,
    editorVisible,
    closeEditor,
    newBrand,
    confirmDelete,
    handleBrand,
    handleActive,
    selectBrand,
    didLoad, setDidLoad
  } = props;

  const [data, setData] = useState(brands);
  const getIndexInParent = (el) => Array.from(el.parentNode.children).indexOf(el);

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      align: 'center',
    },
    {
      title: 'Nombre',
      dataIndex: 'name',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Creado en',
      dataIndex: 'date_created',
      align: 'center',
      render: val => moment(val).format('DD/MM/YYYY'), 
    },
    {
      title: 'Activo',
      align: 'center',
      dataIndex: 'active',
      render: (val, record) => (
        <Switch checked={val} onChange={() => handleActive(record.id, val)} />
      ),
    },
    {
      title: 'Order',
      dataIndex: 'orderView',
      ...getColumnSearchProps('orderView'),
    },
    {
      title: 'Editar',
      align: 'center',
      dataIndex: 'id',
      render: (id, record) => (
        <div className={styles.actions}>
          <div className={styles.edit} onClick={() => openEditor(record)}>
            <Edit />
          </div>
          <div className={styles.divider} />
          <div className={styles.delete} onClick={() => confirmDelete(id)}>
            <Trash />
          </div>
        </div>
      ),
    },
  ];

  const handleReorder = (dragIndex, draggedIndex) => {
    setData((oldState) => {
      const newState = [...oldState];
      const item = newState.splice(dragIndex, 1)[0];
      newState.splice(draggedIndex, 0, item);
      return newState;
    });
  };

  useEffect(() => {
    if (!didLoad) {
      setData(brands);
      setDidLoad(true);
    }
    let start;
    let end;
    const container = document.querySelector(".ant-table-tbody");
    const drake = dragula([container], {
      moves: (el) => {
        start = getIndexInParent(el);
        return true;
      },
    });

    drake.on("drop", (el) => {
      end = getIndexInParent(el);
      handleReorder(start, end);
    });
    
  }, [didLoad]);

  return (
    <div className={styles.view}>
      <Header
        title="Marcas"
        back="/contenidos"
        actions={
          <div className={styles.actions}>
            <Button
              onClick={newBrand}
              type="primary"
              size="large"
              className="main-button">
              NUEVA MARCA
            </Button>
          </div>
        }
      />
      {loading ? (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      ) : (
        <div>
          <Table
            columns={columns}
            dataSource={data}
            rowKey="id"
            pagination={false} 
          />
        </div>
      )}
      <Modal
        destroyOnClose
        closable={false}
        visible={editorVisible}
        footer={false}
        onCancel={closeEditor}
        width={600}
      >
        <Editor
          close={closeEditor}
          brand={brand}
          handleBrand={handleBrand}
          setDidLoad={setDidLoad}
        />
      </Modal>
    </div>
  );
};

export default BrandsUI;
