import { createSlice } from "@reduxjs/toolkit";
import {
  getBranches,
  getDepartments,
  editBranch,
  newBranch,
  deleteBranch,
  changeDelivery,
  getSchedule,
  updateSchedule,
} from "../../api/Branches";

export const branchesSlice = createSlice({
  name: "branches",
  initialState: {
    branches: [],
  },
  reducers: {
    setBranches: (state, action) => {
      state.branches = action.payload;
    },
    setDelivery: (state, action) => {
      const { branch, val } = action.payload;
      const index = state.branches.findIndex((el) => el.id === branch);
      state.branches[index].delivery = val;
    },
    setBranch: (state, action) => {
      const index = state.branches.findIndex(
        (el) => el.id === action.payload.id
      );
      if (index > -1) {
        state.branches[index] = action.payload;
      }
    },
    addBranch: (state, action) => {
      state.branches.push(action.payload);
    },
    removeBranch: (state, action) => {
      const index = state.branches.findIndex((el) => el.id === action.payload);
      if (index > -1) {
        state.branches.splice(index, 1);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBranches, setDelivery, setBranch, addBranch, removeBranch } =
  branchesSlice.actions;

export const getAllBranches = () => async (dispatch) => {
  try {
    const response = await getBranches();
    if (!response.error && response.status === 200) {
      dispatch(setBranches(response.data));
      return {
        status: "success",
        first: response.data[0].id,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getAllDepartments = () => async () => {
  try {
    const response = await getDepartments();
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        departments: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const getDeliverySchedule = () => async () => {
  try {
    const response = await getSchedule();
    if (!response.error && response.status === 200) {
      return {
        status: "success",
        schedule: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const updateDeliverySchedule = (open, close) => async () => {
  try {
    const response = await updateSchedule(open, close);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const deliveryLocation = (id, val) => async (dispatch) => {
  try {
    dispatch(setDelivery({ branch: id, val: !val }));
    const response = await changeDelivery(id);
    if (!response.error && response.status === 200) {
      return {
        status: "success",
      };
    }
    dispatch(setDelivery({ branch: id, val }));
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    dispatch(setDelivery({ branch: id, val }));
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const editLocation = (id, data) => async (dispatch) => {
  try {
    const response = await editBranch(id, data);
    if (!response.error && response.status === 200) {
      dispatch(setBranch(response.data));
      return {
        status: "success",
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const newLocation = (data) => async (dispatch) => {
  try {
    const response = await newBranch(data);
    if (!response.error && response.status === 200) {
      dispatch(addBranch(response.data));
      return {
        status: "success",
        branch: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const deleteLocation = (id) => async (dispatch) => {
  try {
    const response = await deleteBranch(id);
    if (!response.error && response.status === 200) {
      dispatch(removeBranch(id));
      return {
        status: "success",
        // departments: response.data,
      };
    }
    return {
      status: "error",
      type: "unkown",
    };
  } catch (e) {
    return {
      status: "error",
      type: "unknown",
    };
  }
};

export const selectBranches = (state) => state.branches.branches;

export default branchesSlice.reducer;
