import {Table, Modal, Spin, Button, DatePicker, Input} from 'antd';
import {Link} from 'react-router-dom';
import moment from 'moment';
import cx from 'classnames';
// import TableOrders from '../../../components/TableOrders';
import {ReactComponent as Arrow} from '../../../assets/icons/arrow-left.svg';
import {ReactComponent as Cash} from '../../../assets/icons/cash.svg';
import {ReactComponent as Card} from '../../../assets/icons/card.svg';
import {ReactComponent as Check} from '../../../assets/icons/check.svg';
import {ReactComponent as Cancel} from '../../../assets/icons/cancel.svg';
import {getColumnSearchProps} from '../../../utils/tables';
import OrderDetail from '../../../components/OrderDetail';
import {ReactComponent as Calendar} from '../../../assets/icons/calendar.svg';
import styles from '../styles/HistoryUI.module.css';

const {RangePicker} = DatePicker;
const {Search} = Input;


const HistoryUI = props => {
  const {
    loading,
    history,
    order,
    openOrder,
    closeOrder,
    isAccountant,
    exportData,
    beginDate,
    endDate,
    handleDates,
    orderSearch,
  } = props;

  const getIcon = status => {
    if (status === 4) {
      return <Cancel className={styles.driverIcon} />;
    }
    return <Check />;
  };

  const getMethod = status => {
    if (status === 17) {
      return <Card className={styles.methodIcon} />;
    }
    return <Cash className={styles.methodIcon} />;
  };

  const columns = [
    {
      title: 'Ticket',
      dataIndex: 'cod_order',
      align: 'center',
      ...getColumnSearchProps('cod_order'),
    },
    {
      title: 'Usuario',
      dataIndex: 'Customer',
      render: (val) => `${val.name} ${val.lastname}`,
    },
    {
      title: 'Fecha',
      dataIndex: 'date_created',
      render: val => moment(val).format('DD/MM/YY - hh:mma'),
      align: 'center',
    },
    {
      title: 'Tienda',
      dataIndex: 'location',
      render: val => val.name,
      align: 'center',
    },
    {
      title: 'Pago',
      dataIndex: 'order_payments',
      render: val => getMethod(val[0].id),
      align: 'center',
      filters: [
        {
          value: 'Pago Efectivo',
          text: 'Efectivo',
        },
        {
          value: 'Pago Tarjeta',
          text: 'Tarjeta',
        },
      ],
      onFilter: (value, record) => record.order_payments[0].name === value,
      filterMultiple: false,
    },
    {
      title: 'Monto',
      dataIndex: 'total',
      render: (val, order) => `${order.currency}${parseFloat(val).toFixed(2)}`,
      align: 'center',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      render: val => getIcon(val),
      align: 'center',
      filters: [
        {
          value: 5,
          text: 'Completadas',
        },
        {
          value: 4,
          text: 'Canceladas',
        },
      ],
      onFilter: (value, record) => record.status === value,
      filterMultiple: false,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Link to={isAccountant ? '#' : '/'} className={styles.back}>
          {!isAccountant && (
            <div className={styles.backButton}>
              <Arrow />
            </div>
          )}
          <span>Ordenes</span>
        </Link>
        <div>
          <Search
            placeholder="Buscar por código"
            className={cx(styles.input, styles.search)}
            allowClear
            enterButton
            size="large"
            loading={loading}
            onSearch={orderSearch}
          />
          <RangePicker
            size="large"
            suffixIcon={<Calendar />}
            className={styles.picker}
            placeholder={['Fecha Inicio', 'Fecha Final']}
            allowClear={false}
            value={[beginDate, endDate]}
            onChange={handleDates}
          />
          <Button disabled={loading} size="large" type="primary" onClick={exportData}>
            Exportar
          </Button>
        </div>
      </div>
      {!loading ? (
        <div className={styles.orders}>
          <Table
            dataSource={history}
            columns={columns}
            rowKey="id"
            onRow={record => ({
              onClick: () => openOrder(record)
            })}
          />
        </div>
      ) : (
        <div className={styles.loader}>
          <Spin size="large" />
        </div>
      )}
      <Modal
        destroyOnClose
        wrapClassName={styles.modalOrder}
        footer={false}
        visible={order}
        width={1150}
        closable={false}
        onCancel={closeOrder}>
        <OrderDetail order={order} close={closeOrder} />
      </Modal>
    </div>
  );
};

export default HistoryUI;
